
/* eslint-disable */
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { CompanyClient, VendersDto } from "@/base/news-soft-indicadores-client";

// Typings
import { DataSearch, UserLevel } from "@/base/api.typings";
import { FieldRules } from "./action-controller.interfaces";

enum StatusSelectedItems {
    All,
    Some,
    None
}

@Component
export default class Search extends Vue {
    // Component Properties -----------------------------
    @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;
    @Prop({ type: String, default: "" }) readonly label!: string;
    @Prop({ type: Boolean, default: false }) readonly clearable!: boolean;

    @Prop({ type: Boolean, default: false }) readonly multiple!: boolean;
    @Prop() readonly value: any | any[];
    @Prop() readonly data!: DataSearch[];

    // Component Properties Default Values -----------------------------
    @Prop({ type: String, default: "" }) readonly placeholder!: string;
    @Prop({ type: String, default: "" }) readonly hint!: string;
    @Prop({ type: String, default: "text" }) readonly itemText!: string;
    @Prop({ type: String, default: "value" }) readonly itemValue!: string;
    @Prop({ type: String, default: "disabled" }) readonly itemDisabled!: string;
    @Prop({ type: Boolean, default: false }) readonly chips!: boolean;
    @Prop({ type: Boolean, default: false }) readonly smallChips!: boolean;
    @Prop({ type: Boolean, default: false }) readonly returnObject!: boolean;
    @Prop({ type: Boolean, default: false }) readonly deletableChips!: boolean;
    @Prop({ type: Boolean, default: false }) readonly cacheItems!: boolean;
    @Prop({ type: Boolean, default: false }) readonly disableSelectAll!: boolean;

    @Prop() readonly rules!: FieldRules;

    // Computed Properties ------------------------------
    
    onClear():void{
        if (this.$refs.refSearch != null) {
            const refSearch = this.$refs.refSearch as HTMLSelectElement;
            refSearch.focus(); 
            refSearch.blur(); 
        }
        this.$emit("on-clear");
    }

    get color(): string {
        return this.getStatusSelectedItemsFromComboBox() ==
            StatusSelectedItems.All
            ? "indigo darken-4"
            : "";
    }

    get icon(): string {
        if (!this.multiple) return "";

        switch (this.getStatusSelectedItemsFromComboBox()) {
            case StatusSelectedItems.All:
                return "mdi-close-box";
            case StatusSelectedItems.Some:
                return "mdi-minus-box";
            default:
                return "mdi-checkbox-blank-outline";
        }
    }

    get isAllSelected(): boolean {
        return (
            this.multiple &&
            this.selectedItems &&
            (this.selectedItems as any[]).filter(
                f => f.value > Number.MIN_VALUE
            ).length <= 0
        );
    }

    get isDataAvailable(): boolean {
        return (this.data != null && this.data.length > 0) || false;
    }

    get isItemsEmpty(): boolean {
        const items = this.getItems;
        return !((items && items.length > 0) || false);
    }

    get isThereSelectedValue(): boolean {
        return (
            (this.selectedItems != null && this.selectedItems.length > 0) ||
            false
        );
    }

    get userLevelIsGroup(): boolean {
        return this.userLevel == UserLevel.Group;
    }

    // Local variables ----------------------------------

    isLoading = false;
    isSearching = false;
    userLevel: UserLevel = UserLevel.None;
    dialog = false;

    get getItems(): DataSearch[] {
        return this.isDataAvailable ? this.data : this.items;
    }

    items: DataSearch[] = [];
    search: string | null = null;
    selectedItems: any | any[] = null;
    timerToSearch = 0;

    @Watch('disableSelectAll')
    onDisableSelectAll(newValue: boolean, oldValue: boolean): void {
        if (newValue == true && oldValue == false) {
            const item = this.getItems[0];
            if (this.multiple) {
                this.selectedItems = [item]
            } else {
                this.selectedItems = item
            }
        }
    }

    @Watch("search", { immediate: false, deep: false })
    async getSearch(value: string): Promise<void> {
        clearTimeout(this.timerToSearch);

        if (value != null && value != "") {
            this.timerToSearch = setTimeout(
                await this.makeSearch,
                500,
                value.trim()
            );
        } else {
            this.items = [];
        }
    }

    @Watch("selectedItems", { immediate: true, deep: true })
    synchronizeInputData(): void {
        if(this.getStatusSelectedItemsFromComboBox() == StatusSelectedItems.All) {
            this.$emit("input", this.getItems);
            this.$emit("change", this.getItems);
        } else {
            this.$emit("input", this.selectedItems);
            this.$emit("change", this.selectedItems);
        }

    }

    mounted(): void {
        if (this.value != null) {
            this.selectedItems = this.value;
        }

        setTimeout(() => {
            this.$watch(() => {
                return (this.$refs.refSearch as any).isMenuActive;
            }, (isMenuActive) => {
                if (!isMenuActive) {
                    this.$emit('blur');
                }
            })
        }, (1000));
    }
  
    @Watch("value", { immediate: true })
    wathValue(value: any): void {
        this.selectedItems = null;
        if (value != null) {
            this.selectedItems = value;
        }
    }

    // @Click
    selectAllItems(): void {
        this.$nextTick((() => {
            const items = this.getItems;

            if (this.getStatusSelectedItemsFromComboBox() != StatusSelectedItems.All) {
                // Add
                this.selectedItems = items
            } else {
                // Remove
                this.selectedItems = [];
            }
            this.$emit("input", this.getItems);
        }).bind(this));
    }

    // Local methods ------------------------------------

    saveDefaultFilters(): void {
        this.$emit("save-filters", this.selectedItems);
    }

    restoreDefaultFilters(): void {
        this.$emit("restore-filters");
    }
 
    getStatusSelectedItemsFromComboBox(): StatusSelectedItems {
        if (!this.multiple) return StatusSelectedItems.None;

        let length = this.isThereSelectedValue ? this.selectedItems.length : 0;
        let total = this.isItemsEmpty ? 0 : this.getItems?.length;

        return total <= 0
            ? StatusSelectedItems.None
            : length == total
            ? StatusSelectedItems.All
            : length > 0
            ? StatusSelectedItems.Some
            : StatusSelectedItems.None;
    }

    async makeSearch(searchFor: string): Promise<void> {
        if (this.isSearching) return;

        if (searchFor === null || searchFor === "")
            throw new Error("Parameter 'searchFor' is null or white space.");

        try {
            this.isSearching = true;

            let client = new CompanyClient();
            if (this.data == null || this.data == undefined) {
                let localVender: VendersDto[] = await client.listVenders(
                    1,
                    10,
                    searchFor
                );

                this.items = localVender.map(function(item) {
                    return {
                        id: item.sisgemVenderId,
                        value: item.id,
                        text: item.sisgemVenderId + " - " + item.name
                    } as DataSearch;
                });
            }
        } finally {
            this.isSearching = false;
        }
    }
}
