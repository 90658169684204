export function formatDateDayMonth(value: Date): string { 
    const date = new Date(value);
    return date.toISOString().substr(8, 2) + "/" + date.toISOString().substr(5, 2);
}

export function formatDateMonthYear(value: Date): string {
    return value.toISOString().substr(5, 2) + "/" + value.toISOString().substr(0, 4);
}

export function formatDateMonth(value: Date): string {
    const months = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez']
    return months[Number(value.toISOString().substr(5, 2))-1];
}

export function formatDateDayMonthYear(value: Date): string {
    return value.toISOString().substr(8, 2) + "/" + value.toISOString().substr(5, 2) + "/" + value.toISOString().substr(0, 4);
}

export function formatCurrency(value: number, fractionDigits = 0): string {
    return `R$ ${formatDecimal(value, fractionDigits)}`;
}

export function formatDecimal (value: number, fractionDigits = 0): string {
    const  [number, decimals] = value.toFixed(fractionDigits).split('.');

    if (decimals == null)
        return numberWithDots(number);

    return [numberWithDots(number), decimals].join(',');
}

function numberWithDots(value: number | string): string {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}