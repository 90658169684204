import { Profile, SignoutResponse, User, UserManager, UserSettings } from 'oidc-client';
import { OpenIdClientSettings } from './api-authorization-constants';
import { UserClient } from "@/base/news-soft-indicadores-client";
import { UserLevel } from '@/base/api.typings';
import { Sema } from "async-sema";
import '@/extensions';

export interface State {
    returnUrl?: string;
}

export interface AuthResult {
    status: AuthenticationResultStatus;
    state?: State;
    message?: string;
}

export class AuthorizeService {
    private _callbacks: { callback: () => void | Promise<void>, subscription: number }[] = [];
    private _nextSubscriptionId = 0;
    private _user: User | null = null;
    private readonly _semaphore: Sema;

    // By default pop ups are disabled because they don't work properly on Edge.
    // If you want to enable pop up authentication simply set this flag to false.
    private _popUpDisabled = true;

    private _userManager: UserManager;

    private _userLevel: UserLevel = UserLevel.None;

    constructor() {
        this._userManager = AuthorizeService.createUserManager();
        this.configureUserManager();
        this._semaphore = new Sema(1);
        
        this.subscribe(this.setUserLevel.bind(this));
    }

    async getUserLevel(): Promise<UserLevel> {
  
        if (this._userLevel == null || this._userLevel == UserLevel.None){
            await this.setUserLevel();
        }

        return this._userLevel;
    }


    async getUserGuid(): Promise<string> {
        return (await this.getUser()).sub;
    }


    private async setUserLevel(): Promise<void> {
        const isAuth = await this.isAuthenticated();

        if (isAuth == true) {
            this._userLevel = await this.searchUserLevel();
        } else {
            this._userLevel = UserLevel.None;
        }
    }


    private async searchUserLevel(): Promise<UserLevel> {
        await this._semaphore.acquire();
        try {
            if (this._userLevel != null && this._userLevel != UserLevel.None){
                return this._userLevel;
            }

            const client = new UserClient();
            const data = await client.userGET((await this.getUser()).sub);
       
            if (data.venderId != null) {
                return UserLevel.Vender;
            } else if (data.companyIds != null) {
                return UserLevel.Company;
            } else if (data.companyGroupId != null) {
                return UserLevel.Group
            } else {
                return UserLevel.None
            }
        } catch {       
            return UserLevel.Undefined;
        } finally {
            this._semaphore.release();
        }
    }
    

    async isAuthenticated(): Promise<boolean> {
        try {
            const user = await this.getUser();
            return user != null;
        } catch {
            return false;
        }
    }

    async getUser(): Promise<Profile> {
        if (this._user && this._user.profile) {
            return this._user.profile;
        }

        let user: User | null = null;

        if (!String.isNullOrWhiteSpace(process.env.CORDOVA_PLATFORM)) {
            const cacheUser = window.localStorage.getItem('user');
            if (!String.isNullOrWhiteSpace(cacheUser)) {
                user = JSON.parse(cacheUser);
            }
        }

        if (user == null) {
            user = await this._userManager.getUser();
        }

        if (user == null) {
            throw Error("User not authenticated")
        }

        user = new User(user as UserSettings);

        if (user.expired) {
            user = null;
        }

        if (user != null) {
            return user.profile;
        }

        throw Error("User not authenticated")
    }

    async getAccessToken(): Promise<string> {
        
        const user = await this._userManager.getUser();

        if (user != null) {
            return user.access_token;
        }

        throw Error("User not authenticated")
    }

    

    // We try to authenticate the user in three different ways:
    // 1) We try to see if we can authenticate the user silently. This happens
    //    when the user is already logged in on the IdP and is done using a hidden iframe
    //    on the client.
    // 2) We try to authenticate the user using a PopUp Window. This might fail if there is a
    //    Pop-Up blocker or the user has disabled PopUps.
    // 3) If the two methods above fail, we redirect the browser to the IdP to perform a traditional
    //    redirect flow.
    async signIn(state: State): Promise<AuthResult> {
        
        try {
            const silentUser = await this._userManager.signinSilent(this.createArguments(state));
            this.updateState(silentUser);
            return this.success(state);
        } catch (silentError) {
            // User might not be authenticated, fallback to popup authentication
            //console.log("Silent authentication error: ", silentError);

            try {
                if (this._popUpDisabled) {
                    throw new Error('Popup disabled. Change \'AuthorizeService.js:AuthorizeService._popupDisabled\' to false to enable it.')
                }

                const popUpUser = await this._userManager.signinPopup(this.createArguments(state));
                this.updateState(popUpUser);
                return this.success(state);
            } catch (popUpError: unknown) {
                if ((popUpError as Error).message === "Popup window closed") {
                    // The user explicitly cancelled the login action by closing an opened popup.
                    return this.error("The user closed the window.");
                } else if (!this._popUpDisabled) {
                    //console.log("Popup authentication error: ", popUpError);
                }

                // PopUps might be blocked by the user, fallback to redirect
                try {
                    await this._userManager.signinRedirect(this.createArguments(state));
                    return this.redirect();
                } catch (redirectError: unknown) {
                    //console.log("Redirect authentication error: ", redirectError);
                    return this.error((redirectError as Error).message);
                }
            }
        }
    }

    async completeSignIn(url: string): Promise<AuthResult> {
        try {
            
            const user = await this._userManager.signinCallback(url);
            this.updateState(user);
            return this.success(user && user.state); 
        } catch (error) {
            //console.log('There was an error signing in: ', error);
            return this.error('There was an error signing in.');
        }
    }

    // We try to sign out the user in two different ways:
    // 1) We try to do a sign-out using a PopUp Window. This might fail if there is a
    //    Pop-Up blocker or the user has disabled PopUps.
    // 2) If the method above fails, we redirect the browser to the IdP to perform a traditional
    //    post logout redirect flow.
    async signOut(state: State): Promise<AuthResult> {
        
        try {
            if (this._popUpDisabled) {
                throw new Error('Popup disabled. Change \'AuthorizeService.js:AuthorizeService._popupDisabled\' to false to enable it.')
            }

            await this._userManager.signoutPopup(this.createArguments(state));
            this.updateState(null);
            return this.success(state);
        } catch (popupSignOutError) {
            //console.log("Popup signout error: ", popupSignOutError);
            try {
                await this._userManager.signoutRedirect(this.createArguments(state));
                return this.redirect();
            } catch (redirectSignOutError: unknown) {
                //console.log("Redirect signout error: ", redirectSignOutError);
                return this.error((redirectSignOutError as Error).message);
            }
        }
    }

    async completeSignOut(url: string): Promise<AuthResult> {
        
        try {
            const response = await this._userManager.signoutCallback(url) as SignoutResponse;
            this.updateState(null);
            return this.success((response.state) as State);
        } catch (error: unknown) {
            //console.log(`There was an error trying to log out '${error}'.`);
            return this.error((error as Error).message);
        }
    }

    private updateState(user: User | null): void {
        this._user = user;
        this.notifySubscribers();
    }

    subscribe(callback: () => void): number {
        this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });
        return this._nextSubscriptionId - 1;
    }

    unsubscribe(subscriptionId: number): void {
        const subscriptionIndex = this._callbacks
            .map((element, index) => element.subscription === subscriptionId ? { found: true, index } : { found: false, index: -1 })
            .filter(element => element.found === true);
        if (subscriptionIndex.length !== 1) {
            throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
        }

        this._callbacks = this._callbacks.splice(subscriptionIndex[0].index, 1);
    }

    private notifySubscribers(): void {
        if (this._callbacks == null || this._callbacks.length <= 0){
            return;
        }

        const promises = this._callbacks.filter(callback => typeof callback !== 'function').map(c => c.callback());
        
        if (promises.length > 0){
            Promise.all(promises);
        }

        this._callbacks.filter(callback => typeof callback === 'function').map(c => c.callback());
    }

    private createArguments(state: State) {
        return { useReplaceToNavigate: true, data: state };
    }

    private error(message: string): AuthResult {
        return { status: AuthenticationResultStatus.Fail, message };
    }

    private success(state: State): AuthResult {
        return { status: AuthenticationResultStatus.Success, state };
    }

    private redirect() {
        return { status: AuthenticationResultStatus.Redirect };
    }

    private static createUserManager(): UserManager {
        const userManager = new UserManager(OpenIdClientSettings);

        return userManager;
    }

    private configureUserManager(): void {
        if (!String.isNullOrWhiteSpace(process.env.CORDOVA_PLATFORM)) {
            this._userManager.events.addUserLoaded(((user: User) => {
                window.localStorage.setItem('user', JSON.stringify(user));
                this.updateState(user);
            }).bind(this));
    
            this._userManager.events.addUserSignedOut(async () => {
                try {
                    const user = await this.getUser();
                    if (user == null || user.exp > (new Date().valueOf())) {
                        await this._userManager.removeUser();
                        window.localStorage.removeItem('user');
                        this.updateState(null);
                    }
                } catch (error) {
                    console.error('userSignedOut', error);
                    await this._userManager.removeUser();
                    window.localStorage.removeItem('user');
                    this.updateState(null);
                }
            });
        } else {
            this._userManager.events.addUserLoaded(((user: User) => {
                this.updateState(user);
            }).bind(this));

            this._userManager.events.addUserSignedOut(async () => {
                try {
                    const user = await this.getUser();
                    if (user.exp > (new Date().valueOf())) {
                        await this._userManager.removeUser();
                        this.updateState(null);
                    }
                } catch {
                    await this._userManager.removeUser();
                    this.updateState(null);
                }
            });
        }
    }
}

const authService = new AuthorizeService();

export default authService;

export enum AuthenticationResultStatus {
    Redirect = 'redirect',
    Success = 'success',
    Fail = 'fail'
}

