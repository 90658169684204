export const Itens: {
    active: boolean,
    title: string,
    icon: string,
    link: string,
    items: {
        id: number,
        title: string,
        icon: string,
        link: string
    }[]
}[] = [
        {
            active: false, title: "Administrativo", icon: "mdi-clipboard-text-outline", link: "", items: [
                { id: 101, title: "Empresas", icon: "mdi-bank-outline", link: "/company/list" },
                { id: 102, title: "Usuários", icon: "mdi-account", link: "/user/list" },
                { id: 103, title: "Vendedores", icon: "mdi-account-details", link: "/vender/list" }
            ]
        } 
        // {
        //     active: false, title: "Configurações", icon: "mdi-tools", link: "", items: [
        //         { id: 101, title: "Agrupador", icon: "mdi-tune", link: "/grouper" },
        //         { id: 102, title: "Relatórios", icon: "mdi-account-cog-outline", link: "/reportcompany" }
        //     ]
        // }
        //{
        //    active: false, title: "Relatórios", icon: "mdi-chart-timeline-variant", link: "", items: [
        //        { id: 201, title: "Grupo", icon: "mdi-human-capacity-decrease", link: "/teste/graficoline" },
        //        { id: 202, title: "Empresa", icon: "mdi-bank-outline", link: "/teste/graficobar" },
        //        { id: 203, title: "Vendedor", icon: "mdi-account-details", link: "/teste/graficopie" },
        //    ]
        //}
    ];