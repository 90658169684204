
    import Vue from "vue";
    import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
    import { Pie, mixins } from 'vue-chartjs'
    import { ChartOptions } from 'chart.js';

    const { reactiveProp } = mixins;

    @Component({
        extends: Pie
    })
    export default class GraphicsPie extends Mixins(Vue, Pie, reactiveProp) {

        @Prop({ type: Object }) readonly options?: ChartOptions;

        mounted(): void {
            this.renderChart(this.chartData, this.options);
        }

        @Watch("options", { deep: true })
        woptions():void{ 
            this.renderChart(this.chartData, this.options);
        }
    }
