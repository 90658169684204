
import Vue from "vue";
import { Component, Ref } from "vue-property-decorator";
import RepBar from "@/components/graphics/bar.vue";
import RepPie from "@/components/graphics/pie.vue";
import RepLine from "@/components/graphics/line.vue";
import DateFilter from "@/features/report/date-filter.vue";
import EspecialDatesFilter from "@/features/report/especial-dates-filter.vue";
import ValuesInformation from "@/features/report/values-information.vue";

import {
    CompanyClient,
    ReportInitialDto,
    GrouperDto,
    CompanyDto2,
    UserDto2,
    GrouperAlias,
    GetCompanyGroupQuery,
    DaysOfTheWeek,
    ValuesHeaderPage,
    ValuesTicketHeaderPage
} from "@/base/news-soft-indicadores-client";

import authService from "@/features/api-authorization/authorize-service";

import {
    formatDateMonthYear,
    formatCurrency,
    formatDecimal,
    formatDateMonth,
} from "@/filters/format";

import { ChartData, ChartDataSets, ChartOptions } from "chart.js";
import { ReportInitialType, DataSearch as DataSearchCompany } from "@/base/api.typings";
import Search from "@/components/search.vue";
import { UserLevel } from "@/base/api.typings";
import SearchCompany from "@/components/search-company.vue";
import * as validations from "@/base/validations";
import { FieldRules } from "@/components/action-controller.interfaces";
import { VForm } from "vuetify/types";
 
export interface CompaniesDataMonth {
    year: number;
    month: number;
}
  
export interface SaleByFormPaymentReport {
    id: number,
    caption: string,
    name: string,
    total: string,
    percentage: string
}

interface Color {
    borderColor: string,
    backgroundColor: string
}

interface Colors {
    Current: {
        My: Color,
        Market: Color
    },
    Last: {
        My: Color,
        Market: Color,
    }
}
 

@Component({
    components: {
        RepLine,
        RepBar,
        RepPie,
        Search,
        SearchCompany,
        DateFilter,
        EspecialDatesFilter,
        ValuesInformation
    },
    filters: { formatDateMonthYear, formatCurrency, formatDateMonth },
})

export default class IndicadoresReport extends Vue {

    readonly ReportInitialType = {
        Sales: ReportInitialType.Sales, 
        Market: ReportInitialType.Market,
        MarketPublic: ReportInitialType.MarketPublic
    }

    @Ref() readonly searchCompanyForm!: VForm;
  
    colorsGraph: Colors = {
        Current: {
            My: {
                borderColor: "rgba(54, 162, 235)",
                backgroundColor: "rgba(54, 162, 235, 0.2)"
            },
            Market: {
                borderColor: "rgba(131, 189, 230)",
                backgroundColor: "rgba(131, 189, 230, 0.2)"
            }
        },
        Last: {
            My: {
                borderColor: "rgba(31, 209, 49)",
                backgroundColor: "rgba(31, 209, 49, 0.2)"
            },
            Market: {
                borderColor: "rgba(145, 237, 154)",
                backgroundColor: "rgba(145, 237, 154, 0.2)"
            }
        }
    }

    companyClient = new CompanyClient();
    grouperDescription = "";
    txtSearch = "";
    userLevel: UserLevel = UserLevel.Undefined;
    showPieGrouper = false;
    showPieSaleByFormPayment = false; 
    snackbarText="";
    snackbar=false; 
    tab = ReportInitialType.Sales;
    
    valuesHeaderPage: ValuesHeaderPage = {
        MonthCaption: new Date(),
        MonthValue: 0,
        LastMonthCaption: new Date(),
        LastMonthValue: 0,
        LastYearCaption: new Date(),
        LastYearValue: 0,
        LastMonthLastYearCaption: new Date(),
        LastMonthLastYearValue: 0,
        TotalCurrentPeriodCaption: "",
        TotalCurrentPeriodValue: 0,
        TotalMarketCurrentPeriodValue: 0,
        TotalMarketLastPeriodValue: 0,
        TotalLastPeriodCaption: "",
        TotalLastPeriodValue: 0,
        MarketGrowthPercentageValue: 0,
        GrowthPercentageValue: 0
    };

    valuesTicketHeaderPage: ValuesTicketHeaderPage = {
        MonthValue: 0,
        LastMonthValue: 0,
        LastMonthLastYearValue: 0,
        MonthLastYear: 0,
        TotalCurrentPeriodValue: 0,
        TotalLastPeriodValue: 0,
        TotalMarketValue: 0,
        TotalMarketLastValue: 0
    };

    chartDataTicket: ChartData = { datasets:[] };
    chartDataTicketYears: ChartData = { datasets:[] };
    chartDataSales: ChartData = { datasets:[] };
    chartDataSalesYears: ChartData = { datasets:[] };
    chartDataSaleByFormPayment: ChartData = { datasets:[] };
    chartMarketDataSaleByFormPayment: ChartData = { datasets:[] };
    chartDataGrouper: ChartData = { datasets:[] };
    chartDataHourlySales: ChartData = { datasets:[] };
    chartDataWeekSales: ChartData = { datasets:[] };
    chartDataCompany: ChartData = { datasets:[] };

    dataGrouper: GrouperDto[] = [];
    dataSaleByFormPayment: SaleByFormPaymentReport[] = [];

    userDto2: UserDto2 | null = null;
    companiesDto: CompanyDto2[] | null = null;

    selected: { id: number; name: string }[] = [];
    headers = [
        {
            text: "Cor",
            value: "caption",
            align: "center",
            sortable: false,
        },
        {
            text: "Nome",
            value: "name",
            align: "start",
            width: 125,
        },
        {
            text: "R$",
            value: "total",
            align: "end",
        },
        {
            text: "%",
            value: "percentage",
            align: "end",
            width: 15,
        },
    ];

    headersGroups = [
        {
            text: "Cor",
            value: "caption",
            align: "center",
            sortable: false,
            width: "20"
        },
        {
            text: "Nome",
            value: "name",
            align: "start",
        },
        {
            text: "Margem",
            value: "contributionMargin",
            align: "center",
            sortable: false,
            width: "80"
        },
        {
            text: "R$",
            value: "total",
            align: "end",
            width: "110"
        },
        {
            text: "%",
            value: "percentage",
            align: "end",
            width: "70"
        },
    ];

    colors = [
        "rgba(255, 99, 132)",
        "rgb(123, 75, 240)",
        "rgb(54, 162, 235)",
        "rgb(255, 205, 86)",
        "rgb(125, 32, 32 )",
        "rgb(99, 106, 116)",
        "rgb(142, 10, 6)",
        "rgb(187, 221, 219)",
        "rgb(114, 246, 151)",
        "rgb(175, 209, 124)",
    ];

    companyData: DataSearchCompany[] | null = null;
    selectedCompanies: CompanyDto2[] = [];
    allCompanies: CompanyDto2[] = [];

    lastCompanies: number[] | null = null;
    
    endDate: Date = new Date();
    startDate: Date = this.endDate.addDays(-90);

    selectedEspecialPeriodYear = "";
    selectedEspecialLastPeriodYear = "";
    
    dates = [
        this.startDate.toISODateString(),
        this.endDate.toISODateString()
    ];

    lastDates: string[] = [];

    datef = "";
    menu = false;

    lastSearchDate: Date[] = [];
    lastSearchLastDate: string[] = [];

    readonly baseCompanyRules: FieldRules = [validations.required];

    get companyRules() : FieldRules {
        return this.tab == ReportInitialType.Market && !this.allCompaniesSameCnae
            ? this.baseCompanyRules
            : []; 
    }

    get allCompaniesSameCnae(): boolean {
        return [...new Set(this.allCompanies.map(m => m.cnae))].length == 1;
    }

    async mounted(): Promise<void> {

        this.userLevel = await authService.getUserLevel();
        await this.verifyGrouper();

    }
 
    dateChange = 0;
    onAllCompaniesChange(companies: CompanyDto2[]): void {
        this.allCompanies = companies;
        if (this.dateChange == 0 && this.allCompanies.some(s => [10000000891 , 10000001578].includes(s.newsCompanyId))) {
            this.endDate = new Date(2022, 4, 30);
            this.startDate = new Date(2021, 12, 1);
            this.dates = [this.startDate.toISODateString(), this.endDate.toISODateString()];
        }
        this.dateChange += 1;
    }

    async onSelectedEspecialDates(selectedDates: Date[]): Promise<void> {
 
        this.lastDates[0] = selectedDates[0].toISODateString();
        this.lastDates[1] = selectedDates[1].toISODateString();
        this.dates[0]     = selectedDates[2].toISODateString();
        this.dates[1]     = selectedDates[3].toISODateString();

        this.startDate = selectedDates[2];
        this.endDate = selectedDates[3];

        await this.loadReport();
    }

    async onSelectedEspecialPeriod(periodName: string[]): Promise<void> {
        this.selectedEspecialLastPeriodYear = periodName[0];
        this.selectedEspecialPeriodYear = periodName[1];
    }

    async onSelectedDateFilter(selectedDates: Date[]): Promise<void> {
        this.startDate = selectedDates[0];
        this.endDate = selectedDates[1];
        this.dates[0] = selectedDates[0].toISODateString();
        this.dates[1] = selectedDates[1].toISODateString();
        this.lastDates = []; 
        await this.loadReport();
    }

    async onCancelSelectedEspecialDates(): Promise<void> {
        this.tab = ReportInitialType.Sales;
    }

    async verifyGrouper(): Promise<void> {
        const data = await this.companyClient.companyGroup(
            new GetCompanyGroupQuery()
        );

        if (data.grouperAlias == GrouperAlias.Waiting) {
            this.$router.push("./grouper");
        }

        this.grouperDescription = data.grouperDescription;
    }

    get getDisableCompanySearch(): boolean {
        if (
            this.userLevel != UserLevel.Company &&
            this.userLevel != UserLevel.Group
        ) {
            return true;
        }
        return false;
    }

    getColorIndexGroups(item: GrouperDto): string {
        const colorIndex = this.dataGrouper.indexOf(item);
        return this.colors[colorIndex];
    }

    getColorIndexPayment(item: SaleByFormPaymentReport): string {
        const colorIndex = this.dataSaleByFormPayment.indexOf(item);
        return this.colors[colorIndex];
    }

    onChangeTab(): void { 
        this.lastDates = [];  
        if(this.tab ==  ReportInitialType.Sales){
            this.loadReport();
        }
    }
  
    loadingReport = false;
    async loadReport(): Promise<void> {

  
        if ([UserLevel.Undefined, UserLevel.None].includes(this.userLevel)) {
            return;
        } else if (this.loadingReport == true) {
            return;
        } else if (this.searchCompanyForm.validate() == false) {
            return;
        }

        let companyIds: number[] = [];
        if (this.selectedCompanies != null && this.selectedCompanies.length > 0 && this.selectedCompanies.length != this.allCompanies.length) {
            companyIds = this.selectedCompanies.map((item) => item.newsCompanyId);
        }

        if (this.tab == ReportInitialType.Market && this.allCompaniesSameCnae == false && companyIds.length <= 0) {
            return;
        }

        if (
            this.startDate == this.lastSearchDate[0] &&
            this.endDate == this.lastSearchDate[1] &&
            
            this.lastSearchLastDate.length === this.lastDates.length &&
            this.lastSearchLastDate.every((value, index) => value === this.lastDates[index]) &&

            this.lastCompanies?.length === companyIds?.length &&
            this.lastCompanies?.every((value, index) => value === companyIds[index]) &&

            this.tab != ReportInitialType.Market 
        ) {
            return;
        }

        this.lastCompanies = companyIds;
        this.lastSearchDate = [this.startDate, this.endDate];
        this.lastSearchLastDate = [...this.lastDates];
        
        let cnae = "";
        if (this.tab == ReportInitialType.Market) {
            cnae =  (this.selectedCompanies?.length ?? 0) > 0 ? this.selectedCompanies[0].cnae : this.allCompanies[0].cnae;
        } 
   
        this.$action.processAction({
            action: (async () => {
                try {
                    this.loadingReport = true;
                    const data = await this.companyClient.reportInitial(
                        this.tab,
                        this.dates,
                        this.lastDates,
                        cnae,
                        "",
                        companyIds
                    );
                    this.setaTituloGraficos();
                    this.updateCaptionHeaderPage(this.endDate);
                    this.updateValuesHeaderPage(data);
                    this.updateValuesTicketHeaderPage(data);
                    this.updateHourlySalesChartData(data);
                    this.updateChartData(data);
                    this.updateChartDataGrouper(data);

                    this.dataSaleByFormPayment = [];
                    if(this.tab == ReportInitialType.Sales ){
                        this.updateChartDataSaleByFormPayment(data);
                    }else if (this.tab == ReportInitialType.Market ){
                         this.updateChartMarketDataSaleByFormPayment(data);
                    }
                    this.updateWeekSalesChartData(data);
                    this.updatechartDataCompany(data);

                    this.setaTituloGraficos();
                } finally {
                    this.loadingReport = false;
                }
            }).bind(this),

            showLoadingOverlay: false,
            showSnackbar: false,
        });

    }
 

    updateCaptionHeaderPage(selectedDate: Date): void {

        const selectedYear = selectedDate.toISOString().substr(0, 4);
        const selectedMonth = selectedDate.toISOString().substr(5, 2);

        const CurrentDateMonthCaption = selectedDate;

        const CurrentDateLastMonthCaption = new Date(
            parseInt(selectedYear),
            parseInt(selectedMonth) - 2,
            1
        );
        const CurrentDateLastYearCaption = new Date(
            parseInt(CurrentDateMonthCaption.toISOString().substr(0, 4)) - 1,
            parseInt(CurrentDateMonthCaption.toISOString().substr(5, 2)) - 1,
            1
        );
        const CurrentDateLastMonthLastYearCaption = new Date(
            parseInt(CurrentDateMonthCaption.toISOString().substr(0, 4)) - 1,
            parseInt(selectedMonth) - 2,
            1
        );

        this.valuesHeaderPage.MonthCaption = CurrentDateMonthCaption;
        this.valuesHeaderPage.LastMonthCaption = CurrentDateLastMonthCaption;
        this.valuesHeaderPage.LastYearCaption = CurrentDateLastYearCaption;
        this.valuesHeaderPage.LastMonthLastYearCaption = CurrentDateLastMonthLastYearCaption;
        this.UpdateCaptionPeriodValues();
    }

    UpdateCaptionPeriodValues(): void {


        const lastMonth = this.startDate.getMonth() + 1;
        const currentMonth = this.endDate.getMonth() + 1;

        if (this.IsSameMonth(this.startDate, this.endDate)) {
            const newMonth = lastMonth < 10 ? "0" + lastMonth : lastMonth;
            this.valuesHeaderPage.TotalCurrentPeriodCaption = `${newMonth}/${this.startDate.getFullYear()}`;
            this.valuesHeaderPage.TotalLastPeriodCaption = `${newMonth}/${this.startDate.getFullYear() - 1
                }`;
        } else {
            const newLastMonth = lastMonth < 10 ? "0" + lastMonth : lastMonth;
            const newCurrentMonth =
                currentMonth < 10 ? "0" + currentMonth : currentMonth;

            let lastYear = this.startDate.getFullYear();
            let currentYear = this.endDate.getFullYear();

            this.valuesHeaderPage.TotalCurrentPeriodCaption = `${newLastMonth}/${lastYear} à ${newCurrentMonth}/${currentYear}`;

            if (lastYear == currentYear) {
                lastYear = lastYear - 1;
                currentYear = currentYear - 1;
            } else {
                const difference = currentYear - lastYear + 1;
                currentYear = currentYear - difference;
                lastYear = lastYear - difference;
            }

            this.valuesHeaderPage.TotalLastPeriodCaption = `${newLastMonth}/${lastYear} à ${newCurrentMonth}/${currentYear}`;
        }

        if (this.tab != ReportInitialType.Sales) {
            this.valuesHeaderPage.TotalCurrentPeriodCaption = this.selectedEspecialPeriodYear;
            this.valuesHeaderPage.TotalLastPeriodCaption = this.selectedEspecialLastPeriodYear;
        }

    }

    updateValuesHeaderPage(reportInitialDto: ReportInitialDto): void {
        this.valuesHeaderPage.MonthValue =
            reportInitialDto.totalSalesMonth as number;

        this.valuesHeaderPage.LastMonthValue =
            reportInitialDto.totalSalesLastMonth as number;

        this.valuesHeaderPage.LastYearValue =
            reportInitialDto.totalSalesMonthLastYear as number;

        this.valuesHeaderPage.LastMonthLastYearValue =
            reportInitialDto.totalSalesLastMonthLastYear as number;

        this.valuesHeaderPage.TotalCurrentPeriodValue = reportInitialDto.totalCurrentPeriod as number;
        this.valuesHeaderPage.TotalLastPeriodValue = reportInitialDto.totalLastPeriod as number;

        this.valuesHeaderPage.GrowthPercentageValue = reportInitialDto.growthPercentage as number;
        this.valuesHeaderPage.MarketGrowthPercentageValue = reportInitialDto.marketGrowthPercentage as number;

    }

    updateValuesTicketHeaderPage(initialReport: ReportInitialDto): void {
        this.valuesTicketHeaderPage.LastMonthLastYearValue =
            initialReport.ticketValueLastMonthLastYear as number;
        this.valuesTicketHeaderPage.MonthLastYear =
            initialReport.ticketValueMonthLastYear as number;

        this.valuesTicketHeaderPage.LastMonthValue =
            initialReport.ticketValueLastMonth as number;
        this.valuesTicketHeaderPage.MonthValue =
            initialReport.ticketValueMonth as number;

        this.valuesTicketHeaderPage.TotalCurrentPeriodValue = initialReport.ticketCurrentPeriod as number;
        this.valuesTicketHeaderPage.TotalLastPeriodValue = initialReport.ticketLastPeriod as number;

        this.valuesTicketHeaderPage.TotalMarketValue = initialReport.ticketMarketCurrentPeriod as number;

    }

    updateChartData(data: ReportInitialDto): void {
        const datasets: ChartDataSets[] = [];
        const datasetsTicket: ChartDataSets[] = [];
        
        let total: (number | null)[] = [];
        let totalTicket: (number | null)[] = [];
        let labels: (Date | string)[];

        if (this.IsSameMonth(this.startDate, this.endDate) == true) {
            const totalDays = data.totalByDay.map(item => item.day);
            const totalLastDays = data.totalLastByDay.map(item => item.day);

            const days = [...new Set([...totalDays, ...totalLastDays])].sort((a, b) => a - b);
            labels = days.map(m => m.toString());

            total = [];
            totalTicket = [];

            for (const day of days) {
                total.push(data.totalLastByDay.find(f => f.day == day)?.total ?? 0);
                totalTicket.push(data.totalLastByDay.find(f => f.day == day)?.ticket ?? 0);
            }

            datasets.push({
                label: this.valuesHeaderPage.TotalLastPeriodCaption,
                backgroundColor: "rgba(31, 209, 49, 0.2)",
                borderColor: "rgba(31, 209, 49)",
                data: total,
                borderWidth: 2,
                pointBorderWidth: 1,
                fill: false,
            });

            datasetsTicket.push({
                label: this.valuesHeaderPage.TotalLastPeriodCaption,
                backgroundColor: "rgba(31, 209, 49, 0.2)",
                borderColor: "rgba(31, 209, 49)",
                data: totalTicket,
                borderWidth: 2,
                pointBorderWidth: 1,
                fill: false,
            });

            total = [];
            totalTicket = [];

            for (const day of days) {
                total.push(data.totalByDay.find(f => f.day == day)?.total ?? 0);
                totalTicket.push(data.totalByDay.find(f => f.day == day)?.ticket ?? 0);
            }

            datasets.push({
                label: this.valuesHeaderPage.TotalCurrentPeriodCaption,
                backgroundColor: "rgba(54, 162, 235, 0.2)",
                borderColor: "rgba(54, 162, 235)",
                data: total,
                borderWidth: 2,
                pointBorderWidth: 1,
                fill: false,
            });
            datasetsTicket.push({
                label: this.valuesHeaderPage.TotalCurrentPeriodCaption,
                backgroundColor: "rgba(54, 162, 235, 0.2)",
                borderColor: "rgba(54, 162, 235)",
                data: totalTicket,
                borderWidth: 2,
                pointBorderWidth: 1,
                fill: false,
            });
        } else {
            // Last Period
            let firstDate: Date;
            let lastDate: Date;

            if (this.tab != ReportInitialType.Sales && this.lastDates != null && this.lastDates.length > 1) {
                let [year, month] = this.lastDates[0].split('-');
                firstDate = new Date(Number(year), Number(month) - 1, 1);
                [year, month] = this.lastDates[1].split('-');
                lastDate = new Date(Number(year), Number(month), 0);
            } else {
                const diff = this.endDate.getFullYear() - this.startDate.getFullYear();
                firstDate = new Date(this.startDate.getFullYear() - (1 + diff), this.startDate.getMonth(), 1);
                lastDate = new Date(this.endDate.getFullYear() - (1 + diff), this.endDate.getMonth() + 1, 0);
            }

            let months: Date[] = [];
            
            for (let date = new Date(firstDate.valueOf()); date <= lastDate; date = new Date(date.getFullYear(), date.getMonth() + 1, 1)) {
                months.push(date);
            }

            total = [];
            totalTicket = [];

            for (const month of months) {
                total.push(data.totalLastByMonth.find(f => f.year == month.getFullYear() && f.month == month.getMonth() + 1)?.total ?? null);
                totalTicket.push(data.totalLastByMonth.find(f => f.year == month.getFullYear() && f.month == month.getMonth() + 1)?.ticket ?? null);
            }

            datasets.push({
                label: this.valuesHeaderPage.TotalLastPeriodCaption,
                backgroundColor: "rgba(31, 209, 49, 0.2)",
                borderColor: "rgba(31, 209, 49)",
                data: total,
                borderWidth: 2,
                pointBorderWidth: 1,
                fill: false,
            });

            datasetsTicket.push({
                label: this.valuesHeaderPage.TotalLastPeriodCaption,
                backgroundColor: "rgba(31, 209, 49, 0.2)",
                borderColor: "rgba(31, 209, 49)",
                data: totalTicket,
                borderWidth: 2,
                pointBorderWidth: 1,
                fill: false,
            });


            // Current Period
            firstDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), 1);
            lastDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth() + 1, 0);

            months = [];
            
            for (let date = new Date(firstDate.valueOf()); date <= lastDate; date = new Date(date.getFullYear(), date.getMonth() + 1, 1)) {
                months.push(date);
            }

            if (this.startDate.getFullYear() == this.endDate.getFullYear()) {
                labels = [...new Set(months.sort((a, b,) => a.valueOf() - b.valueOf()).map(m => this.monthDescription(m.getMonth() + 1)))];
            } else {
                labels = [...new Set(months.sort((a, b,) => a.valueOf() - b.valueOf()).map(m => m.toLocaleDateString().substring(3)))];
            }

            total = [];
            totalTicket = [];

            for (const month of months) {
                total.push(data.totalByMonth.find(f => f.year == month.getFullYear() && f.month == month.getMonth() + 1)?.total ?? null);
                totalTicket.push(data.totalByMonth.find(f => f.year == month.getFullYear() && f.month == month.getMonth() + 1)?.ticket ?? null);
            }

            datasets.push({
                label: this.valuesHeaderPage.TotalCurrentPeriodCaption,
                backgroundColor: "rgba(54, 162, 235, 0.2)",
                borderColor: "rgba(54, 162, 235)",
                data: total,
                borderWidth: 2,
                pointBorderWidth: 1,
                fill: false,
            });

            datasetsTicket.push({
                label: this.valuesHeaderPage.TotalCurrentPeriodCaption,
                backgroundColor: "rgba(54, 162, 235, 0.2)",
                borderColor: "rgba(54, 162, 235)",
                data: totalTicket,
                borderWidth: 2,
                pointBorderWidth: 1,
                fill: false,
            });
        }

        this.chartDataSales = {
            labels: labels,
            datasets: datasets,
        };

        this.chartDataTicket = {
            labels: labels,
            datasets: datasetsTicket,
        };
    }

    updateHourlySalesChartData(data: ReportInitialDto): void {
        const datasets: ChartDataSets[] = [];
        const my = "(Meu)";
        const market = "(Mercado)"

        // Current Period
        const hoursCurrentPeriod = data.hourlySales.map(data => data.hour).sort((a, b) => a - b);



        const hoursLastPeriod = data.hourlySalesLastPeriod.map(data => data.hour);
        
        let hours = [...hoursCurrentPeriod, ...hoursLastPeriod];

        if (this.tab == ReportInitialType.Market ) {

            const marketHoursCurrentPeriod = data.marketHourlySales.map(data => data.hour);
            const marketHoursLastPeriod = data.marketHourlySalesLastPeriod?.map(data => data.hour);

            hours.push(...marketHoursCurrentPeriod, ...marketHoursLastPeriod);
        }

        hours = [...new Set(hours)].sort((a, b) => a - b);
        
        const totals: number[] = [];
        const lastTotals: number[] = [];
        const marketTotals: number[] = [];
        const marketLastTotals: number[] = [];

        for (const hour of hours) {
            totals.push(data.hourlySales.find(f => f.hour == hour)?.netTotal ?? 0);
            lastTotals.push(data.hourlySalesLastPeriod.find(f => f.hour == hour)?.netTotal ?? 0);
        }

        if (this.tab == ReportInitialType.Market ) {
            for (const hour of hours) {
                marketTotals.push(data.marketHourlySales.find(f => f.hour == hour)?.netTotal ?? 0);
                marketLastTotals.push(data.marketHourlySalesLastPeriod.find(f => f.hour == hour)?.netTotal ?? 0);
            }
        }

        datasets.push({
            label: (this.tab == ReportInitialType.Market) 
                ? `${this.valuesHeaderPage.TotalLastPeriodCaption} ${my}` 
                : this.valuesHeaderPage.TotalLastPeriodCaption,
            backgroundColor: this.colorsGraph.Last.My.backgroundColor,
            borderColor: this.colorsGraph.Last.My.borderColor,
            data: lastTotals,
            borderWidth: 2,
            pointBorderWidth: 1,
            fill: false,
        });
        datasets.push({
            label: (this.tab == ReportInitialType.Market) 
                ? `${this.valuesHeaderPage.TotalCurrentPeriodCaption} ${my}` 
                : this.valuesHeaderPage.TotalCurrentPeriodCaption,
            backgroundColor: this.colorsGraph.Current.My.backgroundColor,
            borderColor: this.colorsGraph.Current.My.borderColor,
            data: totals,
            borderWidth: 2,
            pointBorderWidth: 1,
            fill: false,
        });

        if (this.tab == ReportInitialType.Market) {
            datasets.push({
                label: `${this.valuesHeaderPage.TotalLastPeriodCaption} ${market}`,
                backgroundColor: this.colorsGraph.Last.Market.backgroundColor,
                borderColor: this.colorsGraph.Last.Market.borderColor,
                data: marketLastTotals,
                borderWidth: 2,
                pointBorderWidth: 1,
                fill: false,
            });
            datasets.push({
                label: `${this.valuesHeaderPage.TotalCurrentPeriodCaption} ${market}`,
                backgroundColor: this.colorsGraph.Current.Market.backgroundColor,
                borderColor: this.colorsGraph.Current.Market.borderColor,
                data: marketTotals,
                borderWidth: 2,
                pointBorderWidth: 1,
                fill: false,
            });
        }

        this.chartDataHourlySales = {
            labels: hours,
            datasets: datasets
        };
    }

    updateWeekSalesChartData(data: ReportInitialDto): void {
        const numberDayWeek = data.totalByDaysOfTheWeek.map(data => data.day);
        const lastNumberDayWeek = data.totalByDaysOfTheWeekLastPeriod.map(data => data.day);
        let dayWeek = [...numberDayWeek, ...lastNumberDayWeek];

        if (this.tab == ReportInitialType.Market ) {
            const marketNumberDayWeek = data.totalMarketByDaysOfTheWeek.map(data => data.day);
            const marketLastNumberDayWeek = data.totalMarketByDaysOfTheWeekLastPeriod.map(data => data.day);
            dayWeek.push(...marketNumberDayWeek, ...marketLastNumberDayWeek);
        } 

        dayWeek = [...new Set(dayWeek)].sort((a,b) => a - b);
        const labels = dayWeek.map(data => this.dayOfWeek(data));

        const totals = [];
        const lastTotals = [];
        const marketTotals = [];
        const marketLastTotals = [];

        for (const day of dayWeek) {
            totals.push(data.totalByDaysOfTheWeek.find(f => f.day == day)?.total ?? 0);
            lastTotals.push(data.totalByDaysOfTheWeekLastPeriod.find(f => f.day == day)?.total ?? 0);

            if (this.tab == ReportInitialType.Market ) {
                marketTotals.push(data.totalMarketByDaysOfTheWeek.find(f => f.day == day)?.total ?? 0);
                marketLastTotals.push(data.totalMarketByDaysOfTheWeekLastPeriod.find(f => f.day == day)?.total ?? 0);
            }
        }

        const my = "(Meu)";
        
        const datasets = [{
            label: (this.tab == ReportInitialType.Market ) 
                ? `${this.valuesHeaderPage.TotalLastPeriodCaption} ${my}` 
                : this.valuesHeaderPage.TotalLastPeriodCaption,
            backgroundColor: this.colorsGraph.Last.My.backgroundColor,
            borderColor: this.colorsGraph.Last.My.borderColor,
            data: lastTotals,
            borderWidth: 2,
            pointBorderWidth: 1,
            fill: false,
        },{
            label: (this.tab == ReportInitialType.Market ) 
                ? `${this.valuesHeaderPage.TotalCurrentPeriodCaption} ${my}` 
                : this.valuesHeaderPage.TotalCurrentPeriodCaption,
            backgroundColor: this.colorsGraph.Current.My.backgroundColor,
            borderColor: this.colorsGraph.Current.My.borderColor,
            data: totals,
            borderWidth: 2,
            pointBorderWidth: 1,
            fill: false,
        }];

        if (this.tab == ReportInitialType.Market ) {
            const market = "(Mercado)";

            datasets.push({
                label: `${this.valuesHeaderPage.TotalLastPeriodCaption} ${market}`,
                backgroundColor: this.colorsGraph.Last.Market.backgroundColor,
                borderColor: this.colorsGraph.Last.Market.borderColor,
                data: marketLastTotals,
                borderWidth: 2,
                pointBorderWidth: 1,
                fill: false,
            });
            datasets.push({
                label: `${this.valuesHeaderPage.TotalCurrentPeriodCaption} ${market}`,
                backgroundColor: this.colorsGraph.Current.Market.backgroundColor,
                borderColor: this.colorsGraph.Current.Market.borderColor,
                data: marketTotals,
                borderWidth: 2,
                pointBorderWidth: 1,
                fill: false,
            });
        }

        this.chartDataWeekSales = {
            labels: labels,
            datasets: datasets
        };
    }

    updateChartDataSaleByFormPayment(data: ReportInitialDto): void {
        const chartDataSaleByFormPayment: ChartData = {
            datasets: [{
                label: "",
                backgroundColor: this.colors,
                borderColor: this.colors,
                borderWidth: 1,
                data: [],
                fill: false,
            }],
        };
    
        if (data.saleByFormPayment.length <= 0) {
            this.dataSaleByFormPayment = [];
            this.showPieSaleByFormPayment = false;
        } else {
            const total = data.saleByFormPayment
                .map(m => m.total)
                .reduce((p, c) => p + c, 0);

            this.showPieSaleByFormPayment = total > 0;

            if (this.showPieSaleByFormPayment == false) {
                this.dataSaleByFormPayment = [];
                return;
            }

            let percentageTotal = 100;
            this.dataSaleByFormPayment = data.saleByFormPayment.map(
                (saleByFormPayment, index) => {
                    let value = parseFloat(((saleByFormPayment.total * 100) / total).toFixed(2));
                    percentageTotal = percentageTotal - value;
                    if (percentageTotal < 0) {
                        value = parseFloat((value + percentageTotal).toFixed(2));
                    }
                    const maxLength = 21;
                    const nameLength = saleByFormPayment.name.length;

                    let grouperName = saleByFormPayment.name.substr(0, Math.min(maxLength, nameLength));

                    if (nameLength > maxLength) {
                        grouperName += "...";
                    }

                    return {
                        id: index,
                        name: grouperName,
                        total: formatDecimal(saleByFormPayment.total ?? 0, 2),
                        percentage: formatDecimal(value ?? 0, 2),
                    } as SaleByFormPaymentReport
                }
            );
        
            chartDataSaleByFormPayment.labels = data.saleByFormPayment.map(saleByFormPayment => saleByFormPayment.name);
 
            chartDataSaleByFormPayment.datasets?.forEach(dataset => {
                dataset.data = this.dataSaleByFormPayment.map(saleByFormPayment =>
                    parseFloat(
                        saleByFormPayment.percentage == null || saleByFormPayment.percentage == ''
                            ? "0.0"
                            :  saleByFormPayment.percentage.replaceAll(",","."))
                    );
            });

            this.chartDataSaleByFormPayment = chartDataSaleByFormPayment;
        }
    }
 
    
    updateChartMarketDataSaleByFormPayment(data: ReportInitialDto): void {
        const datasets: ChartDataSets[] = [];
        const my = "(Meu)";
        const market = "(Mercado)"
 
        const formPaymentCurrentPeriod = data.saleByFormPayment.map(data => data.name);
        const formPaymentLastPeriod = data.saleByFormPaymentLastPeriod.map(data => data.name);
         
        const marketFormPaymentCurrentPeriod = data.marketSaleByFormPayment.map(data => data.name);
        const marketFormPaymentLastPeriod = data.marketSaleByFormPaymentLastPeriod?.map(data => data.name);

        let formPayments = [...formPaymentCurrentPeriod, ...formPaymentLastPeriod,...marketFormPaymentCurrentPeriod, ...marketFormPaymentLastPeriod];
 
        formPayments = [...new Set(formPayments)].sort();
    
        const totals: number[] = [];
        const lastTotals: number[] = [];
        const marketTotals: number[] = [];
        const marketLastTotals: number[] = [];

        for (const name of formPayments) {
            totals.push(data.saleByFormPayment.find(f => f.name == name)?.total ?? 0);
            lastTotals.push(data.saleByFormPaymentLastPeriod.find(f => f.name == name )?.total ?? 0);
            marketTotals.push(data.marketSaleByFormPayment.find(f => f.name == name)?.total ?? 0);
            marketLastTotals.push(data.marketSaleByFormPaymentLastPeriod.find(f => f.name == name)?.total ?? 0);
        }

        datasets.push({
            label:   `${this.valuesHeaderPage.TotalLastPeriodCaption} ${my}` ,
            backgroundColor: this.colorsGraph.Last.My.backgroundColor,
            borderColor: this.colorsGraph.Last.My.borderColor,
            data: lastTotals,
            borderWidth: 2,
            pointBorderWidth: 1,
            fill: false            
        });
        datasets.push({
            label: `${this.valuesHeaderPage.TotalCurrentPeriodCaption} ${my}` ,
            backgroundColor: this.colorsGraph.Current.My.backgroundColor,
            borderColor: this.colorsGraph.Current.My.borderColor,
            data: totals,
            borderWidth: 2,
            pointBorderWidth: 1,
            fill: false,
        });

         
        datasets.push({
            label: `${this.valuesHeaderPage.TotalLastPeriodCaption} ${market}`,
            backgroundColor: this.colorsGraph.Last.Market.backgroundColor,
            borderColor: this.colorsGraph.Last.Market.borderColor,
            data: marketLastTotals,
            borderWidth: 2,
            pointBorderWidth: 1,
            fill: false,
        });
        datasets.push({
            label: `${this.valuesHeaderPage.TotalCurrentPeriodCaption} ${market}`,
            backgroundColor: this.colorsGraph.Current.Market.backgroundColor,
            borderColor: this.colorsGraph.Current.Market.borderColor,
            data: marketTotals,
            borderWidth: 2,
            pointBorderWidth: 1,
            fill: false,
        });
        

        this.chartMarketDataSaleByFormPayment = {
            labels: formPayments,
            datasets: datasets
        };
    }

    updateChartDataGrouper(data: ReportInitialDto): void {
        const chartDataGrouper: ChartData = {
            datasets: [
                {
                    label: "",
                    backgroundColor: this.colors,
                    borderColor: this.colors,
                    borderWidth: 1,
                    data: []
                },
            ],
        };

        if (data.groupers == null || data.groupers.length <= 0) {
            this.dataGrouper = [];
            this.showPieGrouper = false;
        } else {
            let total = 0.0;
            let percentageTotal = 100;

            data.groupers.forEach((grouper) => (total += grouper.total ?? 0));

            this.showPieGrouper = total > 0;

            if (this.showPieGrouper == false) {
                this.dataGrouper = [];
                return;
            }

            data.groupers.sort((a, b) => (b.total ?? 0) - (a.total ?? 0));

            this.dataGrouper = data.groupers.map((grouper, index) => {
                let value = grouper.total == null ? 0 : grouper.total;

                value = total == 0 ? 0 : parseFloat(((value * 100) / total).toFixed(2));

                percentageTotal = percentageTotal - value;

                if (percentageTotal < 0) {
                    value = parseFloat((value + percentageTotal).toFixed(2));
                }

                const maxLength = 21;

                const nameLength = grouper.name?.length ?? 0;

                let grouperName =
                    grouper.name?.substr(0, Math.min(maxLength, nameLength)) ?? "";

                if (nameLength > maxLength) {
                    grouperName += "...";
                }

                return {
                    id: index,
                    name: grouperName,
                    contributionMargin: formatDecimal(grouper.contributionMargin ?? 0, 2),
                    total: formatDecimal(grouper.total ?? 0, 2),
                    percentage: formatDecimal(value ?? 0, 2),
                } as GrouperDto | unknown;
            }) as GrouperDto[];

            chartDataGrouper.labels = data.groupers.map(function (grouper) {
                return grouper.name?.padEnd(60, " ");
            }) as string[];

            chartDataGrouper.datasets?.forEach((dataset) => {
                dataset.data = this.dataGrouper
                    .map(function (grouper) {
                        return parseFloat(
                            grouper.percentage == null ? "0" : grouper.percentage.toString()
                        );
                    })
                    .sort((a, b) => b - a);
            });

            this.chartDataGrouper = chartDataGrouper;
        }
    }

    updatechartDataCompany(data: ReportInitialDto): void {
        const datasets: ChartDataSets[] | undefined = [];
        let labels: string[] = [];

        const backgroundColors = [
            "rgba(54, 162, 235, 0.2)",
            "rgba(31, 209, 49, 0.2)",
            "rgba(100, 50, 90, 0.2)",
        ];

        const borderColors = [
            "rgba(54, 162, 235)",
            "rgba(31, 209, 49)",
            "rgba(100, 50, 90 )",
        ];

        if (this.tab == ReportInitialType.Sales) {
            if (this.IsSameMonth(this.startDate, this.endDate) == true) {
                const days = [
                    ...new Set(
                        data.totalByDayByCompany
                            ?.map((item) => Number(item.day))
                            .sort(function (a, b) {
                                if (a > b) return 1;
                                if (a < b) return -1;
                                return 0;
                            })
                    ),
                ];

                labels = [];
                for (let i = 0; i < days.length; i++) {
                    labels.push(days[i].toString());
                }

                const companies = [
                    ...new Set(
                        data.totalByDayByCompany
                            ?.map((item) => Number(item.sisgemCompanyId))
                            .sort(function (a, b) {
                                if (a > b) return 1;
                                if (a < b) return -1;
                                return 0;
                            })
                    ),
                ];

                for (let i = 0; i < companies.length; i++) {
                    const total: number[] | undefined = [];
                    for (let d = 0; d < days.length; d++) {
                        total.push(
                            Number(
                                data.totalByDayByCompany
                                    ?.filter(
                                        (f) => f.sisgemCompanyId == companies[i] && f.day == days[d]
                                    )
                                    .map((item) => item.total) as number[]
                            )
                        );
                    }

                    let backgroundColor = backgroundColors[i];
                    let borderColor = borderColors[i];
                    if (i > 2) {
                        backgroundColor = `rgba(${this.randoncolor()}`;
                        borderColor = `${backgroundColor},2)`;
                        backgroundColor = `${backgroundColor})`;
                    }

                    datasets.push({
                        label: companies[i].toString(),
                        backgroundColor: backgroundColor,
                        borderColor: borderColor,
                        data: total,
                        borderWidth: 2,
                        pointBorderWidth: 1,
                        fill: false,
                    });
                }
            } else {
                const months: number[][] = [[], []];
                let total: number[] = [];
                const companies = [
                    ...new Set(
                        data.totalByMonthByCompany
                            ?.map((item) => Number(item.sisgemCompanyId))
                            .sort()
                    ),
                ];

                data.totalByMonthByCompany?.forEach(function (e) {
                    let exists = false;
                    for (let i = 0; i < months.length; i++) {
                        if (months[i][0] == e.year && months[i][1] == e.month) {
                            exists = true;
                            break;
                        }
                    }
                    if (exists == false) {
                        months.push([Number(e.year), Number(e.month)]);
                    }
                });

                for (let i = 0; i < months.length; i++) {
                    if (months[i][1] != undefined) {
                        labels.push(String(this.monthDescription(Number(months[i][1]))));
                    }
                }

                for (let c = 0; c < companies.length; c++) {
                    total = [];
                    for (let i = 0; i < months.length; i++) {
                        if (months[i][1] != undefined) {
                            total.push(
                                Number(
                                    data.totalByMonthByCompany
                                        ?.filter(
                                            (f) =>
                                                f.sisgemCompanyId == companies[c] &&
                                                f.year == months[i][0] &&
                                                f.month == months[i][1]
                                        )
                                        .map((item) => item.total) as number[]
                                )
                            );
                        }
                    }

                    let backgroundColor = backgroundColors[c];
                    let borderColor = borderColors[c];
                    if (c > 2) {
                        backgroundColor = `rgba(${this.randoncolor()}`;
                        borderColor = `${backgroundColor},2)`;
                        backgroundColor = `${backgroundColor})`;
                    }

                    datasets.push({
                        label: companies[c].toString(),
                        backgroundColor: backgroundColor,
                        borderColor: borderColor,
                        data: total,
                        borderWidth: 2,
                        pointBorderWidth: 1,
                        fill: false,
                    });
                }
            }
        } else {

            if (data.totalPeriodByCompany != null && data.totalPeriodByCompany.length > 0) {

                const companies = [
                    ...new Set(
                        data.totalPeriodByCompany
                            ?.map((item) => Number(item.sisgemCompanyId))
                            .sort(function (a, b) {
                                if (a > b) return 1;
                                if (a < b) return -1;
                                return 0;
                            })
                    ),
                ];

                const total1: number[] | undefined = [];
                const total2: number[] | undefined = [];

                for (let i = 0; i < companies.length; i++) {

                    labels.push(companies[i].toString());

                    total1.push(
                        Number(
                            data.totalPeriodByCompany
                                ?.filter(
                                    (f) => f.sisgemCompanyId == companies[i]
                                )
                                .map((item) => item.totalLastPeriod) as number[]
                        )
                    );

                    total2.push(
                        Number(
                            data.totalPeriodByCompany
                                ?.filter(
                                    (f) => f.sisgemCompanyId == companies[i]
                                )
                                .map((item) => item.totalCurrentPeriod) as number[]
                        )
                    );
                }

                datasets.push({
                    label: this.valuesHeaderPage.TotalLastPeriodCaption,
                    backgroundColor: backgroundColors[1],
                    borderColor: borderColors[1],
                    data: total1,
                    borderWidth: 2,
                    pointBorderWidth: 1,
                    fill: false,
                });

                datasets.push({
                    label: this.valuesHeaderPage.TotalCurrentPeriodCaption,
                    backgroundColor: backgroundColors[0],
                    borderColor: borderColors[0],
                    data: total2,
                    borderWidth: 2,
                    pointBorderWidth: 1,
                    fill: false,
                });

            }
        }
        this.chartDataCompany = {
            labels: labels,
            datasets: datasets,
        };
    }

    randoncolor(): string {
        const rgb = Math.floor(Math.random() * 12987465)
            .toString(16)
            .padStart(6, "0")
            .substr(0, 6);

        const rgb1 = [
            parseInt(rgb.substr(1, 2), 16),
            parseInt(rgb.substr(3, 2), 16),
            parseInt(rgb.substr(5, 2), 16),
        ];
        return rgb1.join(",");
    }

    IsSameMonth(lastDate: Date, currentDate: Date): boolean {
        return (
            lastDate.getMonth() == currentDate.getMonth() &&
            lastDate.getFullYear() == currentDate.getFullYear()
        );
    }

    monthDescription(month: number): string {
        let result = "";
        switch (month) {
            case 1:
                result = "JAN";
                break;
            case 2:
                result = "FEV";
                break;
            case 3:
                result = "MAR";
                break;
            case 4:
                result = "ABR";
                break;
            case 5:
                result = "MAI";
                break;
            case 6:
                result = "JUN";
                break;
            case 7:
                result = "JUL";
                break;
            case 8:
                result = "AGO";
                break;
            case 9:
                result = "SET";
                break;
            case 10:
                result = "OUT";
                break;
            case 11:
                result = "NOV";
                break;
            case 12:
                result = "DEZ";
                break;
        }
        return result;
    }

    dayOfWeek(day: DaysOfTheWeek): string {
        switch (day) {
            case DaysOfTheWeek.Sunday:
                return "DOM";
            case DaysOfTheWeek.Mondey:
                return "SEG";
            case DaysOfTheWeek.Tuesday:
                return "TER";
            case DaysOfTheWeek.Wednesday:
                return "QUA";
            case DaysOfTheWeek.Thursday:
                return "QUI";
            case DaysOfTheWeek.Friday:
                return "SEX";
            case DaysOfTheWeek.Saturday:
                return "SAB";
            default:
                throw new Error(`Day of Week not implemented ${day}`)
        }
    }


    optionsSalesMonths: ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        title: {
            display: true,
            text: "Vendas",
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        callback: function(value:string|number):string {
                        return formatCurrency(value as number, 2);
                      },
                        beginAtZero: true
                    },
                },
            ],
        },
        tooltips: {
            callbacks: {
                label: (item) => formatCurrency(item.yLabel as number, 2)
            }
        }
    };

    optionsCompany: ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        title: {
            display: true,
            text: "Vendas Por Empresa",
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        callback: function(value:string|number):string {
                        return formatCurrency(value as number, 2);
                      },
                        beginAtZero: true
                    },
                },
            ],
        },
        tooltips: {
            callbacks: {
                label: (item) => formatCurrency(item.yLabel as number, 2)
            }
        }
    };

    optionsHourlySales: ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        title: {
            display: true,
            text: "Vendas Por Hora",
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        callback: function(value:string|number):string {
                        return formatCurrency(value as number, 2);
                      },
                        beginAtZero: true
                    },
                },
            ],
        },
        tooltips: {
            callbacks: {
                label: (item) => formatCurrency(item.yLabel as number, 2)
            }
        }
    };

    optionsWeekSales: ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        title: {
            display: true,
            text: "Vendas Por Dia da Semana",
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        callback: function(value:string|number):string {
                        return formatCurrency(value as number, 2);
                      },
                        beginAtZero: true
                    },
                },
            ],
        },
        tooltips: {
            callbacks: {
                label: (item) => formatCurrency(item.yLabel as number, 2)
            }
        }
    };

    optionsTicketMonths: ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        title: {
            display: true,
            text: "Tickets por mês",
        },
        legend: {
            labels: {},
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        callback: function(value:string|number):string {
                        return formatCurrency(value as number, 2);
                      },
                        beginAtZero: true
                    },
                },
            ],
        },
        tooltips: {
            callbacks: {
                label: (item) => formatCurrency(item.yLabel as number, 2)
            }
        }
    };

    optionsSaleByFormPayment: ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
         title: {
            display: true,
            text: "Vendas Por Formas de Pagamento",
        }, 
        legend:{
            display:false,            
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        callback: function(value:string|number):string {
                        return `${value}%`;
                      },
                        beginAtZero: true
                    },
                },
            ],
        },
        tooltips: {
            callbacks: {
                label: (item) => `${ item.yLabel }%`
            }
        } 
    };

    optionsGrouper: ChartOptions = {
        responsive: true,
        legend: {
            display: false,
        },
    };

    optionsMarketSalesByFormPayment: ChartOptions = {
        responsive: true, 
        maintainAspectRatio: false,
         title: {
            display: true,
            text: "Vendas Por Formas de Pagamento",
        },
       
        scales: {
            yAxes: [
                {
                    ticks: {
                        callback: function(value:string|number):string {
                        return `${value}%`;
                      },
                        beginAtZero: true
                    },
                },
            ],
        },
        tooltips: {
            callbacks: {
                label: (item) => `${item.yLabel }%`
            }
        }
    };
 
    setaTituloGraficos(): void {
        this.optionsSalesMonths!.title!.text = "Vendas por mês";
        this.optionsTicketMonths!.title!.text = "Tickets por mês";
        this.optionsCompany!.title!.text = "Vendas por mês por empresa";

        if (this.IsSameMonth(this.startDate, this.endDate) == true) {
            this.optionsCompany!.title!.text = "Vendas por dia por empresa";
            this.optionsSalesMonths!.title!.text = "Vendas por dia";
            this.optionsTicketMonths!.title!.text = "Tickets por dia";
        }
    }

}
