export class IndicadoresBaseClient {
    getBaseUrl(defaultUrl: string): string {
        if (defaultUrl != null && defaultUrl !== "") {
            return defaultUrl;
        } else {
            return process.env.VUE_APP_API_URL;
        }
    }

    getArrayParamQuery(paramName: string, array: number[] | string[] | undefined): string {
        if (array == null) {
            return "";
        }

        const items: string[] = [];
        for(const item in array) {
            items.push(`${paramName}=${item}`)
        }

        return items.join('&');
    }
}