const configure = function() {

    // eslint-disable-next-line no-extra-boolean-cast
    if (!process.env.CORDOVA_PLATFORM) {
        return;
    }

    document.addEventListener('deviceready', () => {
        console.log('deviceready');
        document.addEventListener('pause', () => console.log('pause'), false)
        document.addEventListener('resume', () => console.log('resume'), false)
        document.addEventListener('backbutton', () => console.log('backbutton'), false)
        document.addEventListener('menubutton', () => console.log('menubutton'), false)
        document.addEventListener('searchbutton', () => console.log('searchbutton'), false)
        document.addEventListener('startcallbutton', () => console.log('startcallbutton'), false)
        document.addEventListener('endcallbutton', () => console.log('endcallbutton'), false)
        document.addEventListener('volumedownbutton', () => console.log('volumedownbutton'), false)
        document.addEventListener('volumeupbutton', () => console.log('volumeupbutton'), false)
        document.addEventListener('activated', () => console.log('activated'), false)
        document.addEventListener('cordovacallbackerror', () => console.log('cordovacallbackerror'), false)
    }, false);

    window.handleOpenURL = function(url: string) {
        try {
            if (!(url && url !== '' && url.trim().length > 0)) {
                return;
            }
            SafariViewController.hide();
            const uri = new URL(url);
            const path = `${(process.env.CORDOVA_PLATFORM ? '/#' : '')}${uri.pathname}${uri.search}`
    
            window.location.href = path;
        } catch(e) {
            console.error('Error on Url Scheme', { url, e })
        }
    }
}

configure();