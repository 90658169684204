if (!URLSearchParams.prototype.appendArray) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    URLSearchParams.prototype.appendArray = function (name: string, values: any[]) {
        if (values != null) {
            for (const value of values) {
                this.append(name, value.toString());
            }
        }
    }
}

if (!String['isNullOrWhiteSpace']) {
    String.isNullOrWhiteSpace = (value: string | null| undefined): value is null | undefined => {
        return !(!!value && !!(value.trim()));
    };
}

if (!Date.prototype.toISODateString) {
    Date.prototype.toISODateString = function () {
        return this.toISOString().substring(0, 10);
    };
}

if (!Date.prototype.addDays) {
    Date.prototype.addDays = function(days) {
        const date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }
}

if (!Date.prototype.addMonths) {
    Date.prototype.addMonths = function(months) {
        const date = new Date(this.valueOf());
        date.setMonth(date.getMonth() + months);
        return date;
    }
}

export { }