import { ApplicationPaths, LoginActions, LogoutActions } from './api-authorization-constants';
import { RouteConfig } from 'vue-router';

const apiRoutes: RouteConfig[] = [
  {
    path: ApplicationPaths.Login,
    component: () => import('./login.vue'),
    props: { action: LoginActions.Login }
  },
  {
    path: ApplicationPaths.LoginFailed,
    component: () => import('./login.vue'),
    props: { action: LoginActions.LoginFailed }
  },
  {
    path: ApplicationPaths.LoginCallback,
    component: () => import('./login.vue'),
    props: { action: LoginActions.LoginCallback }
  },
  {
    path: ApplicationPaths.Profile,
    component: () => import('./login.vue'),
    props: { action: LoginActions.Profile }
  },
  {
    path: ApplicationPaths.Register,
    component: () => import('./login.vue'),
    props: { action: LoginActions.Register }
  },
  {
    path: ApplicationPaths.LogOut,
    component: () => import('./logout.vue'),
    props: { action: LogoutActions.Logout }
  },
  {
    path: ApplicationPaths.LogOutCallback,
    component: () => import('./logout.vue'),
    props: { action: LogoutActions.LogoutCallback }
  },
  {
    path: ApplicationPaths.LoggedOut,
    component: () => import('./logout.vue'),
    props: { action: LogoutActions.LoggedOut }
  }
];

export default apiRoutes;