
import Vue from "vue";
import {
    CompanyClient,
    ConfigurationClient,
    CompanyDto2,
    SaveReportCompaniesCommand,
} from "@/base/news-soft-indicadores-client";
import { UserLevel } from "@/base/api.typings";
import authService from "@/features/api-authorization/authorize-service";

import { Component, Emit, Prop, PropSync, VModel, Watch } from "vue-property-decorator";
import Search from "@/components/search.vue";
import { FieldRules } from "./action-controller.interfaces";

interface CompanySearch extends CompanyDto2 {
    disabled?: boolean;
}

@Component({
    components: { Search },
})
export default class SearchCompany extends Vue {
    
    // Component V-Model -----------------------------------------------
    @VModel({ type: Array, default: [] }) selectedCompanies!: CompanySearch[]; 

    // Component Properties --------------------------------------------
    @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;
    @Prop({ type: String, default: "" }) readonly label!: string;
    @Prop({ type: Boolean, default: false }) readonly clearable!: boolean;
    @Prop({ type: Boolean, default: false }) readonly multiple!: boolean;
    
    // Component Properties Default Values -----------------------------
    @Prop({ type: String, default: "" }) readonly placeholder!: string;
    @Prop({ type: String, default: "" }) readonly hint!: string;
    @Prop({ type: Boolean, default: false }) readonly chips!: boolean;
    @Prop({ type: Boolean, default: false }) readonly smallChips!: boolean;
    @Prop({ type: Boolean, default: false }) readonly returnObject!: boolean;
    @Prop({ type: Boolean, default: false }) readonly deletableChips!: boolean;
    @Prop({ type: Boolean, default: false }) readonly cacheItems!: boolean;
    @Prop({ type: Boolean, default: false }) readonly disableSelectAll!: boolean;

    @Prop({ type: Boolean, default: false }) readonly filterByCnae!: boolean;
    @Prop() readonly rules!: FieldRules;

    @PropSync('companies', { default: [] }) allCompanies!: CompanySearch[];

    // Variables (readonly) --------------------------------------------
    readonly allCompaniesLabel = "Todas as Empresas";
    readonly companiesLabel = "Empresas";

    // Variables -------------------------------------------------------
    configurationClient: ConfigurationClient = new ConfigurationClient();
    currentUserGuid = "";
    currentUserLevel: UserLevel = UserLevel.None;
    savedCompanies: number[] = [];
    
    // Computed Properties ---------------------------------------------
    get getLabel(): string {
        return !String.isNullOrWhiteSpace(this.label) 
            ? this.label
            : (this.selectedCompanies == null || this.selectedCompanies.length == 0 || this.selectedCompanies.length == this.allCompanies.length)
                ? this.allCompaniesLabel
                : this.companiesLabel;
    }

    get userLevelIsGroup(): boolean {
        return this.currentUserLevel == UserLevel.Group;
    }

    get hasReportFilterSaved(): boolean {
        return this.savedCompanies.length > 0;
    }

    // Events ----------------------------------------------------------
    @Emit('restore-filters')
    restoreFilters(): void {
        this.selectedCompanies = this.allCompanies.filter(company => this.savedCompanies.includes(company.newsCompanyId));
    }

    @Emit('click:clear')
    onClear(event: Event): Event {
        return event;
    }

    @Emit()
    blur(event: Event): Event {
        return event;
    }

    // Watchers --------------------------------------------------------
    @Watch('filterByCnae')
    onChangeFilterByCnae(value: boolean): void {
        if (value == true) {
            this.onChange(this.selectedCompanies);
        } else {
            this.allCompanies.forEach(f => f.disabled = false);
        }
    }

    // Methods (Life Cycle) --------------------------------------------
    async mounted(): Promise<void> {
        const companyClient = new CompanyClient();
        this.currentUserGuid = await authService.getUserGuid();
        this.currentUserLevel = await authService.getUserLevel();

        const savedCompanies = await this.configurationClient.listReportCompanies();
        this.savedCompanies = savedCompanies.map(m => m.value);

        const allCompanies = await companyClient.list(this.currentUserGuid);

        for (const company of allCompanies) {
            company.displayName = `${company.sisgemCompanyId} - ${company.displayName}`;
        }

        this.allCompanies = allCompanies;

        this.restoreFilters();
    }

    // Methods ---------------------------------------------------------
    onChange(values: CompanySearch[]): void {
        if (!this.filterByCnae) {
            return;
        }

        const cnaes = (values?.length ?? 0) > 0
            ? [...new Set(values.map(m => m.cnae))]
            : [];

        if (cnaes.length <= 0) {
            this.allCompanies.forEach(f => f.disabled = false)
        } else {
            this.allCompanies.forEach(f => f.disabled = !cnaes.includes(f.cnae))
        }
    }

    async saveFilters(selectedItens: CompanyDto2[]): Promise<void> {
        if (this.userLevelIsGroup === false) {
            return;
        }

        try {
            await this.$action.processAction({
                action: async () => {

                    const selectedCompanyIds = selectedItens.map(item => item.newsCompanyId);

                    if (selectedCompanyIds == null || selectedCompanyIds.length <= 0) {
                        await this.configurationClient.deleteReportCompanies();
                    } else {
                        await this.configurationClient.saveReportCompanies(new SaveReportCompaniesCommand({
                            companyIds: selectedCompanyIds
                        }))
                    }

                    this.savedCompanies = this.allCompanies
                        .filter(f => selectedCompanyIds.includes(f.newsCompanyId))
                        .map(m => m.newsCompanyId)
                },
                showLoadingOverlay: false,
                showSnackbar: true,
                ...this.$action.saveMessages,
            });
        } catch (error) {
            console.log("Erro: ", error);
        }
    }
}
