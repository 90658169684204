/* eslint-disable */
type DynamicParam<T> = () => T;

type Param<T> = T | DynamicParam<T>;
 

const _req = (value: any) => {
    if (Array.isArray(value)) return !!value.length
    if (value === undefined || value === null) {
        return false
    }

    if (value === false){
        return true
    }

    if (value instanceof Date) {
        // invalid date won't pass
    }

    if (typeof value === 'object') {
        for (const _ in value) return true
        return false
    }

    return !!String(value).length
}
 

const emailRegex = /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/

/**
 * Obriga que o texto esteja em formato de email.
 * @param value
 */
export function email(value: string) {

    if (!_req(value))
        return 'Preencha este campo';

    if (_req(value) && !emailRegex.test(value))
        return 'E-mail inválido.';

    return true;
}

function getParam<T>(params: Param<T>): T {
    if (typeof params === 'function')
        return (params as any)();

    return params;
}


export function minValue(min: Param<number | Date>) {
    return (value: string) => {
        if (!_req(value))
            return 'Preencha este campo';

        const minValue = getParam(min);

        if (+value < +minValue)
            return `Deve ser maior ou igual a  ${minValue}.`;

        return true;
    };
}

export function required(value: any) {

    if (!_req(value))
        return 'Preencha este campo';

    return true;
} 