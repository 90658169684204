
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import {
    formatDateMonthYear,
    formatCurrency,
    formatDecimal,
    formatDateMonth,
} from "@/filters/format";

import {
    ValuesHeaderPage,
    ValuesTicketHeaderPage,
} from "@/base/news-soft-indicadores-client";
import { ReportInitialType } from "@/base/api.typings";

@Component({
    filters: {
        formatDateMonthYear,
        formatCurrency,
        formatDecimal,
        formatDateMonth,
    },
})
export default class ValuesInformation extends Vue {
    @Prop() readonly valuesHeaderPage!: ValuesHeaderPage;
    @Prop() readonly valuesTicketHeaderPage!: ValuesTicketHeaderPage;
    @Prop({ required: true, type: Number }) tab!: ReportInitialType;

    update = 0;

    @Watch('valuesHeaderPage', { deep: true, immediate: true })
    @Watch('ValuesTicketHeaderPage', { deep: true, immediate: true })
    @Watch('tab', { immediate: true })
    forceRender() : void {
        this.update += 1;
    }

    readonly ReportInitialType = {
        Sales: ReportInitialType.Sales, 
        Market: ReportInitialType.Market,
        MarketPublic: ReportInitialType.MarketPublic
    }

    get showSinglePeriodValues(): boolean { 
        return this.tab != ReportInitialType.Sales;
        
    }

    get GetPercentBetweenMonthsLastYear(): string {
        return this.formatPercentualValue(
            this.PercentualValue(
                this.valuesHeaderPage.LastMonthLastYearValue,
                this.valuesHeaderPage.LastYearValue
            )
        );
    }

    get GetPercentBetweenMonthsCurrentYear(): string {
        return this.formatPercentualValue(
            this.PercentualValue(
                this.valuesHeaderPage.LastMonthValue,
                this.valuesHeaderPage.MonthValue
            )
        );
    }

    get GetPercentBetweenYearsLastMonth(): string {
        return this.formatPercentualValue(
            this.PercentualValue(
                this.valuesHeaderPage.LastMonthLastYearValue,
                this.valuesHeaderPage.LastMonthValue
            )
        );
    }

    get GetPercentBetweenYearsCurrentMonth(): string {
        return this.formatPercentualValue(
            this.PercentualValue(
                this.valuesHeaderPage.LastYearValue,
                this.valuesHeaderPage.MonthValue
            )
        );
    }

    get GetPercentTicketBetweenMonthsLastYear(): string {
        return this.formatPercentualValue(
            this.PercentualValue(
                this.valuesTicketHeaderPage.LastMonthLastYearValue,
                this.valuesTicketHeaderPage.MonthLastYear
            )
        );
    }

    get GetPercentTicketBetweenMonthsCurrentYear(): string {
        return this.formatPercentualValue(
            this.PercentualValue(
                this.valuesTicketHeaderPage.LastMonthValue,
                this.valuesTicketHeaderPage.MonthValue
            )
        );
    }

    get GetPercentTicketBetweenYearsLastMonth(): string {
        return this.formatPercentualValue(
            this.PercentualValue(
                this.valuesTicketHeaderPage.LastMonthLastYearValue,
                this.valuesTicketHeaderPage.LastMonthValue
            )
        );
    }

    get GetPercentTicketBetweenYearsCurrentMonth(): string {
        return this.formatPercentualValue(
            this.PercentualValue(
                this.valuesTicketHeaderPage.MonthLastYear,
                this.valuesTicketHeaderPage.MonthValue
            )
        );
    }

    get GetPercentPeriod(): string { 

        if(this.tab == ReportInitialType.MarketPublic){
          
            return this.formatPercentualValue(
                this.PercentualValue(
                    this.valuesHeaderPage.TotalMarketLastPeriodValue,
                    this.valuesHeaderPage.TotalMarketCurrentPeriodValue
                )
            );
        }
        return this.formatPercentualValue(
            this.PercentualValue(
                this.valuesHeaderPage.TotalLastPeriodValue,
                this.valuesHeaderPage.TotalCurrentPeriodValue
            )
        );
    }

    get GetPercentTicketPeriod(): string {
     
        
        if( this.tab == ReportInitialType.MarketPublic){
            
            return this.formatPercentualValue(
                this.PercentualValue(
                    this.valuesTicketHeaderPage.TotalMarketLastValue,
                    this.valuesTicketHeaderPage.TotalMarketValue
                )
            ); 
        }
        
        return this.formatPercentualValue(
            this.PercentualValue(
                this.valuesTicketHeaderPage.TotalLastPeriodValue,
                this.valuesTicketHeaderPage.TotalCurrentPeriodValue
            )
        );
    }

    PercentualValue(lastvalue: number, currentValue: number): number {
        if (lastvalue == 0) {
            return 0;
        }
        return ((currentValue - lastvalue) * 100) / lastvalue;
    }

    formatPercentualValue(value: number): string {
        if (value == 0) {
            return "0";
        } else { 
            return value.toFixed(2);
        }
    }

    get getGrowthPercentageValue(): string {
        return formatDecimal(this.valuesHeaderPage.GrowthPercentageValue, 2).toString() + "%";
    }

    get getMarketGrowthPercentageValue(): string {
        return formatDecimal(this.valuesHeaderPage.MarketGrowthPercentageValue, 2).toString() + "%";
    }

}
