
    import Vue from "vue";
    import { Component, Mixins, Prop , Watch} from 'vue-property-decorator';
    import { Bar, mixins } from 'vue-chartjs'
    import { ChartOptions } from 'chart.js';

    const { reactiveProp } = mixins;

    @Component({
        extends: Bar
    })
    export default class GraphicssBar extends Mixins(Vue, Bar, reactiveProp) {

        @Prop({ type: Object }) readonly options?: ChartOptions;

        mounted():void {
            this.renderChart(this.chartData, this.options);
        }

        @Watch("options", { deep: true })
        woptions():void{ 
            this.renderChart(this.chartData, this.options);
        }
        
    }
