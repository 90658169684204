

    import Vue from "vue";
    import { Line, mixins } from 'vue-chartjs'
    import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
    import { ChartOptions } from 'chart.js';

    const { reactiveProp } = mixins;

    @Component({
        extends: Line
    })
    export default class GraphicsLine extends Mixins(Vue, Line, reactiveProp) {

        @Prop( { type:Object } ) readonly options?: ChartOptions;

        mounted():void {
            this.renderChart(this.chartData, this.options);
        }
        
        @Watch("options", { deep: true })
        woptions():void{ 
            this.renderChart(this.chartData, this.options);
        }
    }
