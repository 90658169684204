
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class App extends Vue {
    @Prop() readonly message: string | null | undefined;

    get getMessage(): string {
        return this.message || 'Ops! Parece que houve um erro. \nPor favor tente novamente.'
    }
}
