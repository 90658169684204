import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { pt } from "vuetify/src/locale";

//pt.noDataText = 'Sem Dados'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#510404',
                secondary:'#AB8A8A'
            },
        },
    },
    lang: {
        current: 'pt',
        locales: { pt }
    }
});