
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import moment from "moment";

@Component
export default class DateFilter extends Vue {
    menu = false;
    dates: string[] = []

    @Prop({ type: String, default: "Período"}) readonly label!: string;
    @Prop({ type: Array, default: () => [new Date(), new Date()]}) readonly value!: Date[]
    @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;
    @Prop({ type: Date }) readonly max!: Date;

    @Watch('value', { deep: true, immediate: true })
    onChangeValue(newValue:  Date[]): void {
        this.dates = this.formatValue(newValue);
    }

    get formatedDate(): string {
        const [startDate, endDate] = this.formatValue();
        return [this.formatDate(startDate), this.formatDate(endDate)]
            .filter(f => f != null && f != '')
            .join(' ... ')
    }

    formatValue(v: Date[] = this.value): string[] {
        switch (v.length) {
            case 1:
                return [v[0].toISODateString()];
            case 2:
                return [v[0].toISODateString(), v[1].toISODateString()];
            default:
                return [];
        }
    }

    emitInput(): void {
        const dates: string[] = this.dates;

        if (dates == null) {
            return;
        }

        if (dates[0] == null) {
            return;
        }

        if (dates[1] == null) {
            dates[1] = dates[0];
        }

        if (dates[0] > dates[1]) {
            const date = dates[0];
            dates[0] = dates[1];
            dates[1] = date;
        }

        this.menu = false;

        this.$emit("input", [
            new Date(moment(dates[0]).toDate()),
            new Date(moment(dates[1]).toDate()),
        ]);
    }

    formatDate(date: string | Date): string {
        if (!date) {
            return '';
        }

        const d = date instanceof Date
            ? date.toISOString().substring(0, 10)
            : date

        const [year, month, day] = d.split("-");
        return `${day}/${month}/${year}`;
    }
}
