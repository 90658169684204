import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '@/features/report/index.vue'
import Error from '@/features/home/error.vue';
import { authorizeGuard } from './features/api-authorization/authorize-route'
import apiAuthorizationRoutes from './features/api-authorization/api-authorization-routes'
import { UserLevel } from '@/base/api.typings';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    ...apiAuthorizationRoutes,
    {
        path: '/home/error/:message',
        name: 'error',
        component: Error,
        props: (route) => route.params,
        meta: { authorize: false }
    },
    {
        path: '/',
        name: 'Reports',
        component: Home,
        meta: { authorize: true, userLevelRequired: [UserLevel.Group, UserLevel.Company, UserLevel.Vender] }
    },
    {
        path: '/grouper',
        name: 'config_grouper',
        component: () => import('@/features/grouper/index.vue'),
        meta: { authorize: true, userLevelRequired: [UserLevel.Group] }
    }, 
    {
        path: '/company/list',
        name: 'company_list',
        component: () => import('@/features/company/company-list.vue'),
        meta: { authorize: true, userLevelRequired: [UserLevel.Group] }
    },
    {
        path: '/periodworked/list/:sisgemCompanyId',
        name: 'company_periodworked_list',
        props: (route) => route.params,
        component: () => import('@/features/company/period-worked/period-worked-list.vue'),
        meta: { authorize: true, userLevelRequired: [UserLevel.Group] }
    },
    {
        path: '/user/list',
        name: 'user_list',
        component: () => import('@/features/user/user-list.vue'),
        meta: { authorize: true, userLevelRequired: [UserLevel.Group] }
    },
    {
        path: '/user/register',
        name: 'user_register',
        component: () => import('@/features/user/user-register-edit.vue'),
        meta: { authorize: true, userLevelRequired: [UserLevel.Group] }
    },
    {
        path: '/user/edit/:userId',
        name: 'user_edit',
        props: (route) => route.params,
        component: () => import('@/features/user/user-register-edit.vue'),
        meta: { authorize: true, userLevelRequired: [UserLevel.Group] }
    },
    {
        path: '/vender/list',
        name: 'vender_list',
        component: () => import('@/features/vender/vender-list.vue'),
        meta: { authorize: true, userLevelRequired: [UserLevel.Group] }
    },
    {
        path: '/vender/vendergoal/list/:sisgemVenderId',
        name: 'vender_goal_list',
        props: (route) => route.params,
        component: () => import('@/features/vender/vender-goal-list.vue'),
        meta: { authorize: true, userLevelRequired: [UserLevel.Group]}
    },
    {
        path: '/reports',
        name: 'reports',
        component: Home,
        meta: { authorize: true }
    },
    {
        path: '/public',
        name: 'public', 
        component: () => import('@/features/report/public.vue'),
        meta: { authorize: true }
    },
    {
        path: "*",
        component: Error,
        props: (route) => ({ ...route.params, message: 'Página não encontrada.' }),
    }, 
]

const router = new VueRouter({
    mode: process.env.CORDOVA_PLATFORM ? 'hash' : 'history',
    base: process.env.BASE_URL,
    routes
})

authorizeGuard(router);

export default router
