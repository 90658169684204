export interface DataSearch {
    id?: number,
    value: unknown,
    text: string,
}


export interface DataSearch {
    id?: number,
    value: unknown,
    text: string,
}


export enum UserLevel {
    Undefined = -1,
    None = 0,
    Group = 1,
    Company = 2,
    Vender = 3
}

export enum GrouperAlias {
    Waiting = 0,
    Cost_Center = 17,
    Brands = 65,
    Group = 10
}

export enum ReportInitialType{
    Sales = 0, 
    Market = 1,
    MarketPublic = 2
}