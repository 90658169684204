var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{key:_vm.update},[(_vm.tab == _vm.ReportInitialType.Sales)?_c('v-row',{key:_vm.ReportInitialType.Sales},[_c('v-col',{attrs:{"cols":"12","xl":"6","lg":"6","md":"12","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',[_vm._v("Faturamento do Período")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('table',{staticStyle:{"width":"100%"}},[_c('tr',[_c('td',{staticStyle:{"width":"45%","text-align":"center"}},[_c('v-text-field',{staticClass:"totals",attrs:{"outlined":"","readonly":"","label":_vm._f("formatDateMonthYear")(_vm.valuesHeaderPage.LastMonthLastYearCaption),"value":_vm._f("formatCurrency")(_vm.valuesHeaderPage.LastMonthLastYearValue,2)}})],1),_c('td',{staticStyle:{"width":"10%","text-align":"center","color":"red"}},[_c('div',{class:{
                                        percentualPeriodDown:
                                            _vm.GetPercentBetweenMonthsLastYear <
                                            0,
                                        percentualPeriodUp:
                                            _vm.GetPercentBetweenMonthsLastYear >
                                            0,
                                    }},[_c('div',[_c('v-icon',[_vm._v("mdi-arrow-right")])],1),_c('span',[_vm._v(_vm._s(_vm.GetPercentBetweenMonthsLastYear)+"%")])])]),_c('td',{staticStyle:{"width":"45%","text-align":"center"}},[_c('v-text-field',{staticClass:"totals",attrs:{"outlined":"","readonly":"","label":_vm._f("formatDateMonthYear")(_vm.valuesHeaderPage.LastYearCaption),"value":_vm._f("formatCurrency")(_vm.valuesHeaderPage.LastYearValue,2)}})],1)]),_c('tr',[_c('td',{staticStyle:{"width":"45%","text-align":"center","height":"40px"}},[_c('div',{class:{
                                        percentualPeriodDown:
                                            _vm.GetPercentBetweenYearsLastMonth <
                                            0,
                                        percentualPeriodUp:
                                            _vm.GetPercentBetweenYearsLastMonth >
                                            0,
                                    }},[_c('v-icon',[_vm._v("mdi-arrow-down")]),_c('span',[_vm._v(_vm._s(_vm.GetPercentBetweenYearsLastMonth)+"%")])],1)]),_c('td',{staticStyle:{"width":"10%","text-align":"center","height":"40px"}}),_c('td',{staticStyle:{"width":"45%","text-align":"center","height":"40px"}},[_c('div',{class:{
                                        percentualPeriodDown:
                                            _vm.GetPercentBetweenYearsCurrentMonth <
                                            0,
                                        percentualPeriodUp:
                                            _vm.GetPercentBetweenYearsCurrentMonth >
                                            0,
                                    }},[_c('v-icon',[_vm._v("mdi-arrow-down")]),_c('span',[_vm._v(_vm._s(_vm.GetPercentBetweenYearsCurrentMonth)+"%")])],1)])]),_c('tr',[_c('td',{staticStyle:{"width":"45%","text-align":"center"}},[_c('v-text-field',{staticClass:"totals",attrs:{"outlined":"","readonly":"","label":_vm._f("formatDateMonthYear")(_vm.valuesHeaderPage.LastMonthCaption),"value":_vm._f("formatCurrency")(_vm.valuesHeaderPage.LastMonthValue,2)}})],1),_c('td',{staticStyle:{"width":"10%","text-align":"center"}},[_c('div',{class:{
                                        percentualPeriodDown:
                                            _vm.GetPercentBetweenMonthsCurrentYear <
                                            0,
                                        percentualPeriodUp:
                                            _vm.GetPercentBetweenMonthsCurrentYear >
                                            0,
                                    }},[_c('div',[_c('v-icon',[_vm._v("mdi-arrow-right")])],1),_c('span',[_vm._v(_vm._s(_vm.GetPercentBetweenMonthsCurrentYear)+"%")])])]),_c('td',{staticStyle:{"width":"45%","text-align":"center"}},[_c('v-text-field',{staticClass:"totals",attrs:{"outlined":"","readonly":"","label":_vm._f("formatDateMonthYear")(_vm.valuesHeaderPage.MonthCaption),"value":_vm._f("formatCurrency")(_vm.valuesHeaderPage.MonthValue,2)}})],1)])])])],1)],1),_c('v-col',{attrs:{"cols":"12","xl":"6","lg":"6","md":"12","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',[_vm._v("Ticket do Período")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('table',{staticStyle:{"width":"100%"}},[_c('tr',[_c('td',{staticStyle:{"width":"45%","text-align":"center"}},[_c('v-text-field',{staticClass:"totals",attrs:{"outlined":"","readonly":"","label":_vm._f("formatDateMonthYear")(_vm.valuesHeaderPage.LastMonthLastYearCaption),"value":_vm._f("formatCurrency")(_vm.valuesTicketHeaderPage.LastMonthLastYearValue,2)}})],1),_c('td',{staticStyle:{"width":"10%","text-align":"center"}},[_c('div',{class:{
                                        percentualPeriodDown:
                                            _vm.GetPercentTicketBetweenMonthsLastYear <
                                            0,
                                        percentualPeriodUp:
                                            _vm.GetPercentTicketBetweenMonthsLastYear >
                                            0,
                                    }},[_c('div',[_c('v-icon',[_vm._v("mdi-arrow-right")])],1),_c('span',[_vm._v(_vm._s(_vm.GetPercentTicketBetweenMonthsLastYear)+"%")])])]),_c('td',{staticStyle:{"width":"45%","text-align":"center"}},[_c('v-text-field',{staticClass:"totals",attrs:{"outlined":"","readonly":"","label":_vm._f("formatDateMonthYear")(_vm.valuesHeaderPage.LastYearCaption),"value":_vm._f("formatCurrency")(_vm.valuesTicketHeaderPage.MonthLastYear,2)}})],1)]),_c('tr',[_c('td',{staticStyle:{"width":"45%","text-align":"center","height":"40px"}},[_c('div',{class:{
                                        percentualPeriodDown:
                                            _vm.GetPercentTicketBetweenYearsLastMonth <
                                            0,
                                        percentualPeriodUp:
                                            _vm.GetPercentTicketBetweenYearsLastMonth >
                                            0,
                                    }},[_c('v-icon',[_vm._v("mdi-arrow-down")]),_c('span',[_vm._v(_vm._s(_vm.GetPercentTicketBetweenYearsLastMonth)+"%")])],1)]),_c('td',{staticStyle:{"width":"10%","text-align":"center","height":"40px"}}),_c('td',{staticStyle:{"width":"45%","text-align":"center","height":"40px"}},[_c('div',{class:{
                                        percentualPeriodDown:
                                            _vm.GetPercentTicketBetweenYearsCurrentMonth <
                                            0,
                                        percentualPeriodUp:
                                            _vm.GetPercentTicketBetweenYearsCurrentMonth >
                                            0,
                                    }},[_c('v-icon',[_vm._v("mdi-arrow-down")]),_c('span',[_vm._v(_vm._s(_vm.GetPercentTicketBetweenYearsCurrentMonth)+"%")])],1)])]),_c('tr',[_c('td',{staticStyle:{"width":"45%","text-align":"center"}},[_c('v-text-field',{staticClass:"totals",attrs:{"outlined":"","readonly":"","label":_vm._f("formatDateMonthYear")(_vm.valuesHeaderPage.LastMonthCaption),"value":_vm._f("formatCurrency")(_vm.valuesTicketHeaderPage.LastMonthValue,2)}})],1),_c('td',{staticStyle:{"width":"10%","text-align":"center"}},[_c('div',{class:{
                                        percentualPeriodDown:
                                            _vm.GetPercentTicketBetweenMonthsCurrentYear <
                                            0,
                                        percentualPeriodUp:
                                            _vm.GetPercentTicketBetweenMonthsCurrentYear >
                                            0,
                                    }},[_c('div',[_c('v-icon',[_vm._v("mdi-arrow-right")])],1),_c('span',[_vm._v(_vm._s(_vm.GetPercentTicketBetweenMonthsCurrentYear)+"%")])])]),_c('td',{staticStyle:{"width":"45%","text-align":"center"}},[_c('v-text-field',{staticClass:"totals",attrs:{"outlined":"","readonly":"","label":_vm._f("formatDateMonthYear")(_vm.valuesHeaderPage.MonthCaption),"value":_vm._f("formatCurrency")(_vm.valuesTicketHeaderPage.MonthValue,2)}})],1)])])])],1)],1)],1):(_vm.tab == _vm.ReportInitialType.MarketPublic)?_c('v-row',{key:_vm.ReportInitialType.MarketPublic},[_c('v-col',{attrs:{"cols":"12","xl":"6","lg":"6","md":"12","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',[_vm._v("Índice")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('table',{staticStyle:{"width":"100%"}},[_c('tr',[_c('td',{staticStyle:{"width":"45%","font-size":"x-large","text-align":"center"}},[_c('span',[_vm._v(_vm._s(_vm.valuesHeaderPage.TotalLastPeriodCaption))])]),_c('td',{staticStyle:{"width":"10%","text-align":"center","color":"red"}},[_c('div',{class:{
                                        percentualLabelPeriodDown:
                                            _vm.GetPercentPeriod < 0,
                                        percentualLabelPeriodUp:
                                            _vm.GetPercentPeriod > 0,
                                    }},[_c('div',[_c('v-icon',[_vm._v("mdi-arrow-right")])],1),_c('span',[_vm._v(_vm._s(_vm.GetPercentPeriod)+"%")])])]),_c('td',{staticStyle:{"width":"45%","font-size":"x-large","text-align":"center"}},[_c('span',[_vm._v(_vm._s(_vm.valuesHeaderPage.TotalCurrentPeriodCaption))])])])])])],1)],1),_c('v-col',{attrs:{"cols":"12","xl":"6","lg":"6","md":"12","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',[_vm._v("Ticket do Período")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('table',{staticStyle:{"width":"100%"}},[_c('tr',[_c('td',{staticStyle:{"width":"45%","text-align":"center"}},[_c('v-text-field',{staticClass:"totals",attrs:{"outlined":"","readonly":"","label":_vm.valuesHeaderPage.TotalLastPeriodCaption,"value":_vm._f("formatCurrency")(_vm.valuesTicketHeaderPage.TotalMarketLastValue,2)}})],1),_c('td',{staticStyle:{"width":"10%","text-align":"center"}},[_c('div',{class:{
                                        percentualPeriodDown:
                                            _vm.GetPercentTicketPeriod < 0,
                                        percentualPeriodUp:
                                            _vm.GetPercentTicketPeriod > 0,
                                    }},[_c('div',[_c('v-icon',[_vm._v("mdi-arrow-right")])],1),_c('span',[_vm._v(_vm._s(_vm.GetPercentTicketPeriod)+"%")])])]),_c('td',{staticStyle:{"width":"45%","text-align":"center"}},[_c('v-text-field',{staticClass:"totals",attrs:{"outlined":"","readonly":"","label":_vm.valuesHeaderPage.TotalCurrentPeriodCaption,"value":_vm._f("formatCurrency")(_vm.valuesTicketHeaderPage.TotalMarketValue,2)}})],1)])])])],1)],1)],1):_c('v-row',{key:_vm.ReportInitialType.Market},[_c('v-col',{attrs:{"cols":"12","xl":"6","lg":"6","md":"6","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',[_vm._v("% de Crescimento")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('table',{staticStyle:{"width":"100%"}},[_c('tr',[_c('td',{staticStyle:{"width":"48%","text-align":"center"}},[_c('v-text-field',{staticClass:"totals",attrs:{"outlined":"","readonly":"","label":"Meu","value":_vm.getGrowthPercentageValue}})],1),_c('td',{staticStyle:{"width":"4%"}}),_c('td',{staticStyle:{"width":"48%","text-align":"center"}},[_c('v-text-field',{staticClass:"totals",attrs:{"outlined":"","readonly":"","label":"Mercado","value":_vm.getMarketGrowthPercentageValue}})],1)])])])],1)],1),_c('v-col',{attrs:{"cols":"12","xl":"6","lg":"6","md":"6","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',[_vm._v("Tickets")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('table',{staticStyle:{"width":"100%"}},[_c('tr',[_c('td',{staticStyle:{"width":"48%","text-align":"center"}},[_c('v-text-field',{staticClass:"totals",attrs:{"outlined":"","readonly":"","label":"Meu(s)","value":_vm._f("formatCurrency")(_vm.valuesTicketHeaderPage.TotalCurrentPeriodValue,2)}})],1),_c('td',{staticStyle:{"width":"4%"}}),_c('td',{staticStyle:{"width":"48%","text-align":"center"}},[_c('v-text-field',{staticClass:"totals",attrs:{"outlined":"","readonly":"","label":"Mercado","value":_vm._f("formatCurrency")(_vm.valuesTicketHeaderPage.TotalMarketValue,2)}})],1)])])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }