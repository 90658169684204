import { ApplicationPaths, QueryParameterNames } from './api-authorization-constants';
import VueRouter from 'vue-router';
import authService from './authorize-service';
import { Dictionary } from 'vue-router/types/router';
import { UserLevel } from '@/base/api.typings';
import { TestClient } from '@/base/news-soft-indicadores-client';

let lastTimeCheck = 0;
let result = false;

export function authorizeGuard(router: VueRouter): void {

    // Whenever a route changes,
    // this function is called before rendering the target path
    router.beforeEach(async (to, _, next) => {

        if (to.name == 'error') {
            next();
            return;
        }

        if (result == false || (new Date().valueOf() - lastTimeCheck) > (60 * 60 * 1000)) {
            try {
                const client = new TestClient();
                result = await client.test()
            } catch {
                result = false;
            } finally {
                lastTimeCheck = new Date().valueOf();
            }
        }

        if (result == false) {
            next({
                name: 'error',
                params: {
                    message: 'Servidor indisponível ou sem acesso a internet.'
                }
            });
            return;
        }

        if (to.matched.some(record => record.meta.authorize) == false) {
            // Route does not require authorization
            next();
            return;
        }

        const isAutheticated = await authService.isAuthenticated();

        if (isAutheticated == false) {
            // Route requires authorization and
            // User is not authenticated then
            // Redirect to login page
            const query: Dictionary<string> = {};

            query[QueryParameterNames.ReturnUrl] = to.fullPath;

            next({
                path: ApplicationPaths.Login,
                query
            });
            return;
        }

        const userLevel = await authService.getUserLevel();

        if (userLevel == UserLevel.Undefined) {
            next({
                name: 'error',
                params: {
                    message: 'Usuário não cadastrado ou nível de acesso indefinido.'
                }
            });
            return;
        } 
        
        if (userLevel == UserLevel.None) {
            next({
                name: 'error',
                params: {
                    message: 'Acesso negado.'
                }
            });
            return;
        }
        
        const userLevelRequired: UserLevel[] = [
            ...new Set(to.matched.flatMap(record => record.meta.userLevelRequired))
        ].filter(f => f != null);
            
        if (userLevelRequired.length <= 0) {
            next();
            return;
        } 
        
        if (userLevelRequired.includes(userLevel)) {
            next();
            return;
        } 

        next({
            name: 'error',
            params: {
                message: 'Acesso negado.'
            }
        });
    });
}