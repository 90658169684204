

import Vue from "vue";
import { Component, Watch, Prop, Emit } from "vue-property-decorator";
import {
    EspecialDateClient,
    ListEspecialDateQuery,
    EspecialDateDto
} from "@/base/news-soft-indicadores-client";
import DateFilter from './date-filter.vue';
import { ReportInitialType} from "@/base/api.typings";

@Component({
    components: {
        DateFilter
    }
})
export default class EspecialDatesFilter extends Vue {

    @Prop({ type: Number, default: 0 }) selectedItemTab!: number;
    @Prop({ type: Number, default: 0 }) id!: number;

    especialDateClient = new EspecialDateClient();
    especialDates: EspecialDateDto[] = [];

    formatedDate = "";
    formatedDateLastPeriod = "";
    menu = false;
    menuLastPeriod = false;
    currentPeriod: Date[] = [];
    lastPeriod: Date[] = [];

    showDialogEspecialPeriod = false;
    returnOnCancel = true;

    selectedEspecialDateId = 0;

    selectedPeriodName = "";
    selectedLastPeriodName = "";
    maxDate = new Date();

    get selectedEspecialDateDto(): EspecialDateDto | undefined {
        if (this.selectedEspecialDateId <= 0) {
            return undefined;
        }

        return this.especialDates.find(f => f.id == this.selectedEspecialDateId)
    }

    async mounted(): Promise<void> {
 
        //if(this.id != ReportInitialType.MarketPublic){
            await this.loadEspecialDate();
            const date = new Date();
            const dates = [new Date(date.getFullYear(), date.getMonth(),1), new Date(date)];
            await this.onCurrentInput(dates);

        //} else {
        //    const companyClient = new CompanyClient();
        //    this.maxDate = await companyClient.maxDateAllowed();
        //    const date = this.maxDate;
        //    const dates = [new Date(date.getFullYear(), date.getMonth(),1), new Date(date)];
        //    await this.onCurrentInput(dates);
        //}
       
    }

    defineLastDate(currentDate:Date[]):void {

        const startDate = new Date(currentDate[0].addMonths(-12));
        let endDate = new Date(currentDate[1]).addMonths(-12);
    
        if(currentDate[1].getDate() == 29 && currentDate[1].getMonth() + 1 == 2 && endDate.getDate() == 1 && endDate.getMonth() + 1 == 3){
            endDate = new Date(endDate.getFullYear(), 2, 28);
        }

        this.lastPeriod = [ 
            new Date(startDate),
            new Date(endDate)
        ]; 
    }
 

    async onCurrentInput(values: Date[]): Promise<void> {

        await this.defineLastDate(values);
     
        this.selectedPeriodName = "Personalizado Atual";
        this.selectedLastPeriodName = "Personalizado Anterior";

        this.currentPeriod =  [new Date(values[0]), new Date(values[1])]  ;
        
        this.emitNewValues();
        this.onSelectedPeriod(["Anterior", "Atual"]);
    }

    onLastInput(values: Date[]): void {
        this.lastPeriod = values;
        this.emitNewValues();
        this.onSelectedPeriod(["Anterior", "Atual"]);
    }

    emitNewValues(): void {
   
        this.onSelectedDates([
            this.lastPeriod[0],
            this.lastPeriod[1],
            this.currentPeriod[0],
            this.currentPeriod[1]
        ]);
    }

    async onClickEspecialDates(): Promise<void> { 
        this.returnOnCancel = false; 
        await this.loadEspecialDateAction();  
        await this.defineSpecialPeriod(); 
        this.showDialogEspecialPeriod = true;
    }

    async defineSpecialPeriod(): Promise<void>{
        
        if (this.selectedEspecialDateId <= 0) {
            return;
        }

        if (this.selectedEspecialDateDto == null) {
            return;
        }
    
        this.showDialogEspecialPeriod = false;
        const specialDate = this.selectedEspecialDateDto;
        const now = new Date();

        let startDate: Date;
        let endDate: Date;
        let startDateLastPeriod: Date;
        let endDateLastPeriod: Date;
        let currentYear;
        let lastYear;

        if (specialDate.especialDateItems.length >= 1) {
            startDate = new Date(specialDate.especialDateItems[0].startDate);
            endDate   = new Date(specialDate.especialDateItems[0].endDate);
            currentYear = specialDate.especialDateItems[0].year;
        } else {
            startDate = now;
            endDate   = now;
            currentYear = endDate.getFullYear();
        }

        if (specialDate.especialDateItems.length >= 2) {
            startDateLastPeriod = new Date(specialDate.especialDateItems[1].startDate);
            endDateLastPeriod   = new Date(specialDate.especialDateItems[1].endDate);
            lastYear = specialDate.especialDateItems[1].year;
        } else {
            startDateLastPeriod = startDate;
            endDateLastPeriod   = endDate;
            lastYear = currentYear;
        }

        this.currentPeriod = [new Date(startDate), new Date(endDate)];
        this.lastPeriod = [new Date(startDateLastPeriod), new Date(endDateLastPeriod)];



        const description = specialDate.description;

        let periodNameAppend = "Atual";
        let lastPeriodNameAppend = "Anterior";
  
        periodNameAppend = specialDate.especialDateItems[0].year.toString(); 
        lastPeriodNameAppend = specialDate.especialDateItems[1].year.toString();
         
     
        this.selectedPeriodName = `${description} ${periodNameAppend}`;
        this.selectedLastPeriodName = `${description} ${lastPeriodNameAppend}`;
 
        this.onSelectedDates([
            startDateLastPeriod,
            endDateLastPeriod,
            startDate,
            endDate
        ]);
  
        this.onSelectedPeriod([
            lastYear.toString(),
            currentYear.toString(),
        ]); 
        
        await this.onCurrentInput([new Date(startDate), new Date(endDate)]);
        await this.onLastInput([new Date(startDateLastPeriod), new Date(endDateLastPeriod)]);

    }

    @Emit()
    onSelectedDates(dates: Date[]): Date[] { 
        return dates;
    }

    @Emit()
    onSelectedPeriod(values: string[]): string[] {
        return values;
    }

    @Watch("selectedItemTab", { immediate: true })
    async onSelectedItemTab(value: number): Promise<void> { 
        this.returnOnCancel = true;
        if ( this.id == ReportInitialType.MarketPublic){
            this.showDialogEspecialPeriod = false;
        } else if (value > 0 && value == this.id) {
            this.showDialogEspecialPeriod = true; 
        }
    }

    onCancel(): void {
        if (this.returnOnCancel == true) {
            this.$emit("on-cancel");
        }
        this.showDialogEspecialPeriod = false;
    }

    async loadEspecialDate(): Promise<void> {
        if (this.especialDates != null && this.especialDates.length > 0) {
            return;
        }

        await this.$action.processAction({
            action: this.loadEspecialDateAction.bind(this),
            showLoadingOverlay: false,
            showSnackbar: false,
        });
    }

    async loadEspecialDateAction(): Promise<void> {
        if(this.selectedEspecialDateId==0){
            this.especialDates = await this.especialDateClient.list(new ListEspecialDateQuery());
            if (this.especialDates != null && this.especialDates.length > 0){
                this.selectedEspecialDateId = this.especialDates[0].id;
            }
        }
    } 
}
