
    import Vue from 'vue';
    import { Component } from 'vue-property-decorator';
    import { Itens as Menu } from '@/base/menu';
    import authService from './features/api-authorization/authorize-service';
    import axios from 'axios';
    import ActionController from '@/components/action-controller.vue'
    import { ApplicationPaths } from './features/api-authorization/api-authorization-constants';
    import { UserLevel } from '@/base/api.typings';

    const dismissKey = 'dismiss-app';

    @Component({
        components: {
            ActionController
        }
    })
    export default class App extends Vue {
        drawerRight = false;
        drawer = false;
        isAuthenticated = false;
        items = Menu;
        userLevel: UserLevel = UserLevel.None;

        deferredPrompt: Event | null = null;

        ApplicationPaths = ApplicationPaths;

        get isNotAuthentication() {
            return !this.$route.path.startsWith('/authentication');
        }

        async created(): Promise<void> {

            window.addEventListener("beforeinstallprompt", e => {
                e.preventDefault();

                let dismissDate: Date | null = null;
                const storageValue = localStorage.getItem(dismissKey)
                if (!String.isNullOrWhiteSpace(storageValue)) {
                    dismissDate = new Date(storageValue);
                }

                if (dismissDate == null || this.datediff(dismissDate, new Date()) > 15) {
                    this.deferredPrompt = e;
                }
                
            });

            window.addEventListener("appinstalled", () => {
                this.deferredPrompt = null;
            });

            this.configureAxiosInterceptors();
            
            await this.checkAuthentication(); 
        }

        datediff(first: Date, second: Date) : number {        
            return Math.round((second.valueOf() - first.valueOf()) / (1000 * 60 * 60 * 24));
        }

        dismiss(): void {
            localStorage.setItem(dismissKey, (new Date()).toISOString());
            this.deferredPrompt = null;
        }

        install(): void {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (this.deferredPrompt as any).prompt();
        }

        async updated(): Promise<void> {
            this.addActionControlerToRefs();
            this.userLevel = await authService.getUserLevel();
               
        }

        get getUserLevelGroup(): boolean {
            return (this.userLevel == UserLevel.Group);
        }

        addActionControlerToRefs(): void {
            const vue = Vue.prototype;
            vue.$action = this.$refs.action;

        }

        async checkAuthentication() :Promise<void>{
            const _checkAuthentication = async () => this.isAuthenticated = await authService.isAuthenticated();

            authService.subscribe(_checkAuthentication);

            await _checkAuthentication();
        }

        configureAxiosInterceptors(): void {

            axios.interceptors.request.use(async request => {
                try {
                    const isAuth = await authService.isAuthenticated();
                    if (isAuth) {
                        if (request.headers == null) {
                            request.headers = {};
                        }
                        const token = await authService.getAccessToken();
                        request.headers['Authorization'] = `Bearer ${token}`;
                    }
                } catch {
                    //
                }
                return request;
            });

            axios.interceptors.response.use(undefined, (err) => {
                if (err.response.status == 401) {
                    this.$router.push(ApplicationPaths.Login);
                    return null;
                }
                else {
                    return Promise.reject(err);
                }
            });
        }

    }
