/// <reference types="cordova-plugin-safariviewcontroller"/>
import { InMemoryWebStorage, UserManagerSettings, WebStorageStateStore } from 'oidc-client';

export const QueryParameterNames = {
    ReturnUrl: 'returnUrl',
    Message: 'message'
};

export enum LogoutActions {
    LogoutCallback = 'logout-callback',
    Logout = 'logout',
    LoggedOut = 'logged-out'
}

export enum LoginActions {
    Login = 'login',
    LoginCallback = 'login-callback',
    LoginFailed = 'login-failed',
    Profile = 'profile',
    Register = 'register'
}

const prefix = '/authentication';

export const ApplicationPaths = {
    DefaultLoginRedirectPath: '/',
    ApiAuthorizationPrefix: prefix,
    Login: `${prefix}/${LoginActions.Login}`,
    LoginFailed: `${prefix}/${LoginActions.LoginFailed}`,
    LoginCallback: `${prefix}/${LoginActions.LoginCallback}`,
    Register: `${prefix}/${LoginActions.Register}`,
    Profile: `${prefix}/${LoginActions.Profile}`,
    LogOut: `${prefix}/${LogoutActions.Logout}`,
    LoggedOut: `${prefix}/${LogoutActions.LoggedOut}`,
    LogOutCallback: `${prefix}/${LogoutActions.LogoutCallback}`,
    IdentityRegisterPath: '/Account/Register',
    IdentityManagePath: '/Account/Manage'
};

const clientIds = new Object({
    android: 'indicadores-android-client',
    ios: 'indicadores-ios-client'
})

const navigator = {
    prepare: function () {
        return Promise.resolve(this);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    navigate: function (params: any) {
        if (!params || !params.url) {
            return Promise.reject(new Error("No url provided"));
        }

        SafariViewController.isAvailable(function (available) {
            if (available) {
                SafariViewController.show(
                    {
                        url: params.url
                    },
                    function (result) {
                        console.log({ result });
                    },
                    function (error) {
                        console.log({ error });
                    }
                );
            }
        });

        return Promise.resolve();
    },
    get url() {
        return window.location.href;
    }
}

const OpenIdClientSettingsBuilder: () => UserManagerSettings = () => {
    try {
        // eslint-disable-next-line no-prototype-builtins
        if (clientIds.hasOwnProperty(process.env.CORDOVA_PLATFORM)) {
            let origin = process.env.NODE_ENV === 'production' ? 'brcomnewssoftindicadores:' : 'https://localhost:8080';
            origin += process.env.NODE_ENV === 'production' ? '' : '/#'
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const clientId = (clientIds as any)[process.env.CORDOVA_PLATFORM];
            return {
                authority: process.env.VUE_APP_URL_AUTHORITY,
                client_id: clientId,
                redirect_uri: `${origin}${ApplicationPaths.LoginCallback}`,
                response_type: "code",
                scope: "openid indicadores-api",
                post_logout_redirect_uri: `${origin}${ApplicationPaths.LogOutCallback}`,
                automaticSilentRenew: true,

                redirectNavigator: navigator,

                userStore: new WebStorageStateStore({
                    prefix: clientId,
                })
            }
        }

        return {
            authority: process.env.VUE_APP_URL_AUTHORITY,
            client_id: 'indicadores-web-client',
            redirect_uri: window.location.origin + ApplicationPaths.LoginCallback,
            response_type: "code",
            scope: "openid indicadores-api",
            post_logout_redirect_uri: window.location.origin + ApplicationPaths.LogOutCallback,
            automaticSilentRenew: true,

            userStore: new WebStorageStateStore({
                prefix: 'indicadores-web-client',
                store: new InMemoryWebStorage()
            })
        }
    } catch (error) {
        console.log(error);
        return {
            authority: process.env.VUE_APP_URL_AUTHORITY,
            client_id: 'indicadores-web-client',
            redirect_uri: window.location.origin + ApplicationPaths.LoginCallback,
            response_type: "code",
            scope: "openid indicadores-api",
            post_logout_redirect_uri: window.location.origin + ApplicationPaths.LogOutCallback,
            automaticSilentRenew: true,

            userStore: new WebStorageStateStore({
                prefix: 'indicadores-web-client',
                store: new InMemoryWebStorage()
            })
        }
    }
};

export const OpenIdClientSettings = OpenIdClientSettingsBuilder();


export const AuthMessages = {
    ProcessingLogin: 'Entrando...',
    ProcessingLoginCallback: 'Processando autenticação, por favor aguarde.',
    LoginFailed: 'Falha de login.',
    ProcessingLogout: 'Saindo...',
    LogoutNotInitiated: 'O processo de logout não foi iniciado na página.',
    ProcessingLogoutCallback: 'Saindo...',
    LoggedOut: 'Você saiu com sucesso!'
};