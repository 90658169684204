import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import "@/extensions"
import "./main-handle-device"

Vue.config.productionTip = process.env.NODE_ENV !== 'production'

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
